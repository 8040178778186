// Header Main Menu
.main-menu {
  &__nav {
    ul {
      li {
        display: inline-block;
        align-items: center;
        position: relative;
        padding: 0 0px 0 17px;

        &:hover {
          .main-menu {
            &__dropdown {
              opacity: 1;
              @include transitionSmooth();
              visibility: visible;
              top: 100%;
              transform: scaleY(1);
            }
          }
        }

        a {
          font-size: 17px;
          color: $colorSecondary;
          font-weight: $font-weight-medium;
          position: relative;
          display: inline-block;
          padding: 33px 6px;

          &::before {
            --scale: 0;
            position: absolute;
            content: "";
            height: 4px;
            background: $colorPrimary;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            transform: scaleX(var(--scale));
            transform-origin: var(--x) 50%;
            transition: transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
          }
        }

        &:hover > {
          a {
            color: $colorPrimary;

            &::before {
              --scale: 1;
              background: $colorPrimary;
            }
          }
        }

        & ul {
          visibility: hidden;
          transition: all 0.4s cubic-bezier(0.3, 0.02, 0, 0.6);
          opacity: 0;
          z-index: 100;
          position: absolute;
          top: 100%;
          left: 0;
          width: 200px;
          margin: 0;
          padding: 10px 0;
          background-color: #fff;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
          text-align: left;
          box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.1);
          padding: 15px 0;
          border-radius: 0 0 5px 5px;
          transform: scaleY(0);
          transform-origin: 0 0 0;

          li {
            display: block !important;
            padding: 0 17px;

            a {
              display: block !important;
              font-size: 15px;
              padding: 8.5px 10px;
              color: #656567;
              text-transform: capitalize;
              font-weight: $font-weight-medium;

              &::after {
                opacity: 0;
                display: none;
              }

              &::before {
                width: 3px;
                height: 0px;
                top: 50%;
                transform: translateY(-50%);
                left: 0 !important;
                margin: 0;
                background: $colorJumbo;
                opacity: 0;
                visibility: hidden;
                @include transitionSmooth();
              }
            }

            &:hover {
              a {
                &::before {
                  height: 11px;
                  opacity: 1;
                  visibility: visible;
                  @include transitionSmooth();
                }
              }
            }
          }
        }
      }
    }

    &_sub {
      a {
        &::after {
          content: "\f107";
          font-family: "Font Awesome 5 Free";
          font-weight: 600;
          vertical-align: middle;
          transform: rotate(0deg);
          display: inline-block;
          padding-left: 6px;
          font-size: 12px;
          @include transitionSmooth();
        }
      }

      &:hover {
        a {
          &::after {
            transform: rotate(180deg) translateX(-6px);
            @include transitionSmooth();
          }
        }
      }
    }
  }

  &--light {
    nav {
      ul {
        li {
          a {
            color: $colorWhite;
          }
        }
      }
    }
  }
}

// Radius Theme Mobile Menu
.offscreen-navigation .sub-menu {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
}

.offscreen-navigation li > a {
  display: block;
  position: relative;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.offscreen-navigation .sub-menu li > a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5.5px 0px;
  color: #7d7d7e;

  &:hover {
    color: $colorPrimary;
  }
}

.offscreen-navigation .menu > li {
  padding-bottom: 8px;

  a.animation.opened {
    color: $colorPrimary;
  }
}

.offscreen-navigation li.menu-item-has-children > a:after {
  content: "\f067";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  transition: 0.1s;
  right: 0;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
}

.offscreen-navigation li.menu-item-has-children > a.opened:after {
  content: "\f068";
}

.rt-slide-nav {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  display: none;
  padding: 30px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  box-shadow: 0 0 10px 0 #0000001c;
  top: 100%;
  z-index: 999;
}

.rt-header-menu {
  padding: 21px 15px;
  border-bottom: 1px solid rgba(81, 81, 81, 0.15);

  @include respond-above(md) {
    display: none;
  }
}

.mean-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Main Menu Agency
.main-menu {
  &--agency {
    nav {
      & > ul {
        & > li {
          margin-right: 30px;
          &:first-child {
            padding-left: 0;
          }

          @include respond-below(lg) {
            margin-right: 15px;
          }

          a {
            padding-left: 0;
            padding-right: 0;
            color: $colorWhite;

            &::before {
              height: 1px;
            }
          }
        }
      }
    }
  }
  &--modern {
    padding-left: 150px;
    @include respond-below(xxlg) {
      padding-left: 50px;
    }
  }
  &--startup {
    & > nav {
      & > ul {
        & > li {
          padding: 0 0px 0 30px;
        }
      }
    }
  }
}
