// Reset CSS
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  min-height: 100%;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

i {
  font-style: normal;
}

figure {
  margin: 0;
  padding: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: $colorPrimary;
}

a:hover {
  text-decoration: none;
  outline: none;
  color: $colorPrimary;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

label {
  color: $colorSecondary;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
}

body {
  font-family: $font-family-primary;
  color: $colorSecondary;
  font-size: 16px;
}

p {
  font-family: $font-family-secondary;
  font-weight: normal;
  color: $colorText;
  font-size: 16px;
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-primary;
  color: $colorSecondary;
  line-height: 1.4;
  font-weight: $font-weight-semibold;
}

button {
  cursor: pointer;
}

button.button {
  background: transparent;
  border: 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

::-moz-selection {
  background: $gray-900;
  color: $colorWhite;
  text-shadow: none;
}

::selection {
  background: $gray-900;
  color: $colorWhite;
  text-shadow: none;
}
