// Project
.project-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    .image {
      &__link {
        opacity: 1;
        visibility: visible;
        @include transitionSmooth();
        transform: translateY(-50%) scaleY(1);
      }
      .heading,
      .tags {
        opacity: 1;
        visibility: visible;
      }
      .heading {
        transform: translateY(-47px);
        transition: 1s;
      }
      .tags {
        transform: translateY(-30px);
        transition: 1.2s;
        color: rgba(255, 255, 255, 0.8);
      }
      &__big {
        transform: scale(1.4);
        transition: 1.5s;
      }
    }
  }
  .image {
    display: block !important;
    &__big {
      width: 100%;
      transition: 1s;
    }
    &__link {
      position: absolute;
      right: 0;
      left: 0;
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-30%) scaleY(0);
      background: $colorWildSand;
      text-align: center;
      line-height: 70px;
      font-size: 18px;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      @include transitionSmooth();
      &:hover {
        background: $colorPrimary;
        color: $colorWhite;
      }
    }
  }
  .item-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($colorSecondary, 0.6);
    top: 0;
    left: 100%;
  }
  .heading,
  .tags {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;
    opacity: 0;
    visibility: hidden;
  }
  .heading {
    transform: translateY(0px);
    font-size: 24px;
    font-weight: $font-weight-medium;
  }
  .tags {
    transform: translateY(0px);
    color: $colorWhite;
    font-size: 16px;
  }
}
