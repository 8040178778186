// Business
.business-item {
  &:nth-child(odd) {
    .business-item {
      &__content {
        background: #f8faff;
        &::before {
          background: #e0e8f9;
        }
        &:hover {
          &::after {
            background: $colorPrimary;
          }
        }
      }
    }
  }
  &:nth-child(even) {
    .business-item {
      &__content {
        background: #f1f3f9;
        &::before {
          background: #e6e6e6;
        }
      }
    }
  }
  &:first-child {
    .business-item {
      &__content {
        border-radius: 10px 0 0 10px;
      }
    }
  }
  &:last-child {
    .business-item {
      &__content {
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
  &__content {
    padding: 58px 32px 50px;
    background: $colorWhite;
    position: relative;
    @include respond-below(lg) {
      padding: 58px 15px 50px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      top: 77px;
      left: 0;
      right: 0;
    }
    &::after {
      position: absolute;
      content: "";
      width: 0%;
      height: 1px;
      top: 77px;
      left: 0;
      right: 0;
      z-index: 2;
      @include transitionSmooth;
      opacity: 0;
      visibility: hidden;
    }
    .number {
      display: inline-block;
      line-height: 40px;
      height: 40px;
      width: 40px;
      font-size: 15px;
      color: $colorPrimary;
      background-color: $colorLinkWater;
      text-align: center;
      border-radius: 50%;
      margin-bottom: 15px;
      position: relative;
      @include transitionSmooth;
      z-index: 3;
    }
    .title {
      font-size: 24px;
      margin-bottom: 11px;
    }
    .link {
      color: $colorPrimary;
      font-size: 18px;
      @include transitionSmooth();
      &:hover {
        padding-left: 5px;
        @include transitionSmooth();
        color: $colorSecondary;
      }
    }
    &:hover {
      .number {
        background: $colorPrimary;
        color: $colorWhite;
        @include transitionSmooth;
      }
      &::after {
        width: 100%;
        opacity: 1;
        visibility: visible;
        background: $colorPrimary;
      }
    }
  }
}
