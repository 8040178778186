// Brand
.brand-active {
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .brand-item {
    text-align: center;
    display: flex;
    justify-content: center;
    &__col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__logo {
      filter: grayscale(100%);
      transition: all 1s ease;
    }
    :hover {
      .brand-item {
        &__logo {
          -webkit-filter: invert(100%) sepia(92%) saturate(4039%)
            hue-rotate(222deg) brightness(97%) contrast(97%);
          filter: invert(100%) sepia(92%) saturate(4039%) hue-rotate(222deg)
            brightness(97%) contrast(97%);
          transition: all 1s ease;
        }
      }
    }
  }
}
