// Sticky
.sticky-header {
  background: transparent;
  &.sticky-on.sticky {
    background-color: rgba($colorWhite, 1);
  }
}
.sticky-on.sticky {
  &.header--transparrent {
    border-bottom: 1px solid transparent;
    box-shadow: 0px 7px 0px 0px rgba($colorBlack, 0.03);
  }
  .main-menu {
    &--agency {
      nav {
        & > ul {
          & > li {
            margin-right: 30px;
            @include respond-below(lg) {
              margin-right: 15px;
            }
            & > a {
              padding-left: 0;
              padding-right: 0;
              color: $colorSecondary;
              &:hover {
                color: $colorPrimary;
              }
              &::before {
                height: 1px;
              }
            }
            ul {
              li {
                a {
                  padding: 8.5px 10px;
                }
              }
            }
          }
        }
      }
    }
    &--light {
      nav {
        ul {
          li {
            a {
              color: $colorSecondary;
              &:hover {
                color: $colorPrimary;
              }
            }
          }
        }
      }
    }
  }
  .header-right {
    &--agency {
      ul {
        li {
          .search {
            a {
              color: $colorSecondary;
              &:hover {
                color: $colorPrimary;
              }
            }
          }
        }
      }
    }
  }
}

header.sticky-on.sticky .navbar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba($colorWhite, 1);
  box-shadow: 0px 7px 65px 0px rgba($colorBlack, 0.05);
  @include transitionSmooth();
}
