.timeline-item {
  position: relative;
  z-index: 9;
  .timeline-header {
    .heading {
      @include font-size(36px);
      font-weight: $font-weight-medium;
      margin-bottom: 27px;
      line-height: 1.2;
    }
  }
  .timeline-image {
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
  }
  .timeline-content {
    padding: 30px 39px;
    background-color: $colorWhite;
    box-shadow: 0px 0px 54px 0px rgba(2, 2, 2, 0.05);
    position: relative;
    max-width: 447px;
    .heading {
      font-size: 22px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      filter: drop-shadow(0px 0px 54px rgba(2, 2, 2, 0.4))
        drop-shadow(0px 0px 54px rgba(2, 2, 2, 0.4));
      top: 40px;
    }
  }
  &--left {
    padding-right: 45px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @include respond-below(md) {
      padding-right: 0;
      padding-left: 45px;
      align-items: flex-start;
    }
    .timeline-content {
      &::after {
        border-width: 15px 0 15px 16px;
        right: -16px;
        border-color: transparent transparent transparent $colorWhite;
        @include respond-below(md) {
          border-width: 15px 16px 15px 0;
          left: -16px;
          border-color: transparent $colorWhite transparent transparent;
        }
      }
    }
    .timeline-circle {
      right: -24.5px;
      @include respond-below(md) {
        right: auto;
        left: -13.5px;
      }
    }
  }
  &--right {
    padding-left: 45px;
    .timeline-content {
      &::after {
        border-width: 15px 16px 15px 0;
        left: -16px;
        border-color: transparent $colorWhite transparent transparent;
      }
    }
    .timeline-circle {
      left: -24.5px;
      @include respond-below(md) {
        left: -13.5px;
      }
    }
  }
  .timeline-circle {
    width: 25px;
    height: 25px;
    background: #eaf1ff;
    border-radius: 50%;
    position: absolute;
    top: 40px;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background: $colorPrimary;
      z-index: 2;
      @include center(vertical);
      right: 0;
      margin: 0 auto;
      left: 0;
      border-radius: 50%;
    }
  }
}
.timeline-wrapper {
  &--line {
    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 100%;
      width: 3px;
      background-color: #f4f4f4;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
      @include respond-below(md) {
        left: 0;
      }
    }
  }
  &__row {
    &:last-child {
      .timeline-item {
        .timeline-image {
          @include respond-below(sm) {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}
