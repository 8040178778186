.template-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.5s ease-in-out;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transform-origin: 0 0 0;

  .animation-shape-lines {
    color: $colorPrimary;
    &::before,
    &::after {
      background-color: $colorPrimary;
    }
  }

  .search-form {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: 60%;
    margin: 0 auto;

    input[type="search"] {
      color: rgba($colorSecondary, $alpha: 0.7);
      font-size: 24px;
      text-align: left;
      border: 1px solid #c3c3c3;
      margin: 0 auto;
      padding: 25px 10px 25px 30px;
      outline: none;
      background-color: transparent;
      width: 100%;

      @media only screen and (max-width: 575px) {
        width: 75%;
      }
    }

    .search-btn {
      padding: 24px 10px;
      box-shadow: none;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      position: absolute;
      right: 4px;
      height: 80px;
      width: 80px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      transform: translateY(-50%);
      background: $colorPrimary;
      color: $colorWhite;
      border-radius: 0;

      i {
        &:before {
          font-size: 24px;
          font-weight: 700;
          margin-left: 0;
        }
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: $colorPrimary;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    opacity: 1;
    visibility: visible;
    padding: 3px 15px 5px;
    font-size: 36px;
    font-weight: 300;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
    transition: all 0.3s ease-in-out;

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.template-search.open {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
