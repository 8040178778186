.about-skill {
  &--feature {
    padding-right: 20px;
    margin-left: 20px;
    @include respond-below(sm) {
      padding-right: 0;
      margin-left: 0;
    }
    @include respond-below(xs) {
      padding-right: 0;
      margin-left: 0;
    }
    .item {
      &:last-child {
        .progress {
          margin-bottom: 10px;
        }
      }
    }
  }
  .progress {
    background-color: #dedbdb;
    border-radius: 20px;
    box-shadow: none;
    height: 3px;
    margin-bottom: 53px;
    overflow: visible;
    position: relative;
    margin-top: 44px;
    border: 1px solid transparent;
    padding: 0;
    .lead {
      font-size: 22px;
      bottom: 14px;
      position: absolute;
      color: $colorSecondary;
      font-weight: 400;
    }
  }
  .progress-bar {
    background: $colorPrimary;
    overflow: visible;
    height: 3px;
    &::after {
      @include center(vertical);
      content: "";
      right: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $colorPrimary;
    }
    & > span {
      color: $colorSecondary;
      float: right;
      font-size: 18px;
      margin-right: 10px;
      margin-top: -25px;
      position: absolute;
      padding: 5px;
      top: -20px;
      right: -28px;
      font-weight: $font-weight-medium;
    }
  }
}

// Page Progress
@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}

.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 200ms linear, margin-right 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}

.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap:after {
  position: absolute;
  content: "\f062";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-align: center;
  line-height: 2.3rem;
  font-size: 14px;
  color: $colorPrimary;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: $colorPrimary;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}
