// Team
.team-block {
  $self: &;
  @include transitionSmooth();
  .image {
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 34px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 261px;
      height: 634px;
      bottom: -75%;
      filter: blur(0px);
      background: rgba(0, 0, 0, 0.6);
      right: -44%;
      opacity: 0;
      visibility: hidden;
      @include transitionSmooth();
      z-index: 99999;
    }
  }
  .content {
    .designation {
      font-size: 15px;
      font-weight: $font-weight-medium;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #868686;
    }
    .name {
      font-size: 22px;
    }
  }
  &:hover {
    .social {
      ul {
        li {
          transform: translateX(0px);
          opacity: 1;
          visibility: visible;
          @include transitionSmooth();
          &:nth-child(1n) {
            transition-delay: 0.1s;
          }
          &:nth-child(2n) {
            transition-delay: 0.3s;
          }
          &:nth-child(3n) {
            transition-delay: 0.5s;
          }
          &:nth-child(4n) {
            transition-delay: 0.7s;
          }
          &:nth-child(5n) {
            transition-delay: 0.9s;
          }
        }
      }
    }
    .image {
      &::before {
        opacity: 1;
        visibility: visible;
        @include transitionSmooth();
        filter: blur(100px);
        background: rgba(0, 0, 0, 0.56);
      }
    }
  }
  &--two {
    text-align: center;
    .image {
      margin-bottom: 24px;
      &__member {
        transform: translateX(50px) scale(1.3) translateY(50px);
        filter: grayscale(100%);
        @include transitionSmooth();
      }
    }    
    &:hover {
      .image {
        &::before {
          display: none;
        }
        &__member {
          transform: translateX(0px) scale(1) translateY(0px);
          filter: grayscale(0%);
          @include transitionSmooth();
        }
      }
      .content {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        @include transitionSmooth();
      }
    }    
    .social {
      right: 0;
      left: 0;
      ul {
        li {
          display: inline-block;
          a {
            margin: 0 6.5px;
          }
        }
      }
    }
    .content {
      transform: translateY(-50px);
      opacity: 0;
      visibility: hidden;
      @include transitionSmooth();
      .name {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
        .designation {
          padding-left: 12px;
          font-family: $font-family-secondary;
          color: $gray-600;
          text-transform: capitalize;
          letter-spacing: 0;
          padding-top: 6px;
        }
      }
    }
  }
  &--active {
    .image {
      &::before {
        display: none;
      }
      &__member {
        transform: translateX(0px) scale(1) translateY(0px);
        filter: grayscale(0%);
        @include transitionSmooth();
      }
    }
    .content {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      @include transitionSmooth();
    }
    .social {
      ul {
        li {
          transform: translateX(0px);
          opacity: 1;
          visibility: visible;
          @include transitionSmooth();
          &:nth-child(1n) {
            transition-delay: 0.1s;
          }
          &:nth-child(2n) {
            transition-delay: 0.3s;
          }
          &:nth-child(3n) {
            transition-delay: 0.5s;
          }
          &:nth-child(4n) {
            transition-delay: 0.7s;
          }
          &:nth-child(5n) {
            transition-delay: 0.9s;
          }
        }
      }
    }
  }
}


.social {
  position: absolute;
  bottom: 31.5px;
  right: 26px;
  z-index: 99999;
  ul {
    li {
      transform: translateX(15px);
      opacity: 0;
      visibility: visible;
      @include transitionSmooth();
      transition-delay: 0s;
      &:nth-child(1n) {
        transition-delay: 0.1s;
      }
      &:nth-child(2n) {
        transition-delay: 0.3s;
      }
      &:nth-child(3n) {
        transition-delay: 0.5s;
      }
      &:nth-child(4n) {
        transition-delay: 0.7s;
      }
      &:nth-child(5n) {
        transition-delay: 0.9s;
      }
      a {
        width: 50px;
        height: 50px;
        display: inline-block;
        text-align: center;
        line-height: 50px;
        background: $colorWhite;
        margin: 6.5px 0;
        border-radius: 50%;
        @include font-size(18px);
        @include transitionSmooth();
        &:hover {
          @include transitionSmooth();
          background: $colorPrimary;
          color: $colorWhite;
        }
      }
    }
  }
}
