// Pricing Item
.price-item {
  padding: 56px 30px;
  @include transitionSmooth();
  position: relative;
  @include respond-below(xlg) {
    padding: 56px 15px;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 14px;
    background: $colorPrimary;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    @include transitionSmooth();
    z-index: -1;
  }
  &::before {
    border-radius: 10px 10px 0 0;
    top: 0;
  }
  &::after {
    border-radius: 0px 0px 10px 10px;
    bottom: 0;
  }
  &:hover {
    @include transitionSmooth();
    background: $colorPrimary !important;
    border-radius: 0;
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      @include transitionSmooth();
    }
    &::before {
      top: -14px;
    }
    &::after {
      bottom: -14px;
    }
    .price-item {
      &__header {
        &_icon {
          background: $colorBlueRibbon !important;
          @include transitionSmooth();
        }
      }
      &__title {
        color: $colorWhite;
        @include transitionSmooth();
      }
      &__price {
        color: $colorWhite;
        @include transitionSmooth();
      }
      &__content {
        &_item {
          span {
            color: $colorWhite;
            @include transitionSmooth();
          }
          i {
            color: $colorWhite;
          }
        }
      }
      &__button {
        background: $colorWhite;
        color: $colorPrimary;
        @include transitionSmooth();
        &:hover {
          background: transparent;
          color: #fff;
          border-color: #ffff;
        }
      }
    }
  }
  &__wrapper {
    &:nth-child(odd) {
      .price-item {
        background: #f8faff;
        &:hover {
          border-radius: 0;
        }
      }
    }
    &:nth-child(even) {
      .price-item {
        background: #edf6ff;
        &:hover {
          border-radius: 0;
        }
        &__header {
          &_icon {
            background: $colorWhite;
          }
        }
      }
    }
    &:first-child {
      .price-item {
        border-radius: 10px 0 0px 10px;
        @include respond-below(md) {
          border-radius: 0;
        }
      }
    }
    &:last-child {
      .price-item {
        border-radius: 0px 10px 10px 0px;
        @include respond-below(md) {
          border-radius: 0;
        }
      }
    }
  }
  &__header {
    margin-bottom: 16px;
    &_icon {
      display: inline-flex;
      height: 120px;
      width: 120px;
      justify-content: center;
      align-items: center;
      background: $colorZumthor;
      border-radius: 50%;
      padding: 15px 10px;
      margin-bottom: 30px;
    }
  }
  &__icon {
    line-height: 0;
  }
  &__price {
    font-size: 36px;
    font-weight: $font-weight-medium;
    color: $colorPrimary;
    span {
      font-family: $font-family-secondary;
      font-size: 17px;
      font-weight: $font-weight-regular;
    }
  }
  &__content {
    margin-bottom: 35px;
    &_item {
      margin-bottom: 12px;
      span {
        font-size: 16px;
        color: $colorText;
        font-family: $font-family-secondary;
        padding-left: 10px;
      }
      i {
        color: $gray-750;
        font-size: 12px;
      }
    }
  }
  &__button {
    font-size: 18px;
    color: $colorPrimary;
    border: 1px solid $colorPrimary;
    line-height: 1;
    border-radius: 4px;
    padding: 15px 30px;
    background: transparent;
  }
}

// Price Agency
.price-item {
  &--agency {
    padding: 56px 64px;
    border: 3px solid $colorZumthor;
    border-radius: 10px;
    @include respond-below(xlg) {
      padding: 50px 50px;
    }
    @include respond-below(lg) {
      padding: 50px 15px;
    }
    &::before,
    &::after {
      display: none;
    }
    &:hover {
      border: 3px solid $colorPrimary;
      border-radius: 10px;
      .price-item__content_item {
        i {
          color: $colorWhite;
        }
      }
    }
    .price-item__content_item {
      span {
        color: $colorSecondary;
      }
      i {
        color: $colorSecondary;
      }
    }
  }
}
