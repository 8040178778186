/*
	*******************

		Template name:	Techkit - Technology Bootstrap 5 Template
		Version:        1.0
		Author:         RadiusTheme
		Author url:     https://themeforest.net/user/radiustheme

	*******************


***** CSS INDEX *****
********************/

// 1. Abstracts
@import "abstracts/variable";
@import "abstracts/functions";
@import "abstracts/mixins";

// 2. Vendors
@import "vendors/bootstrap/bootstrap.scss";
@import "abstracts/responsive";

// 3. Base
@import "base/reset";
@import "base/base";
@import "base/animation";

// 4. Layout
@import "layout/navbar";
@import "layout/header";
@import "layout/slider";
@import "layout/section";
@import "layout/footer";

// 5. Components
@import "components/sticky";
@import "components/button";
@import "components/sidebar";
@import "components/alert";
@import "components/modal";
@import "components/about";
@import "components/services";
@import "components/support";
@import "components/review";
@import "components/price";
@import "components/counter";
@import "components/blog";
@import "components/address";
@import "components/form";
@import "components/business";
@import "components/brand";
@import "components/featured";
@import "components/project";
@import "components/case";
@import "components/fullpage";
@import "components/newsletter";
@import "components/team";
@import "components/contact";
@import "components/inner";
@import "components/progress";
@import "components/faq";
@import "components/timeline";
@import "components/careers";
@import "components/pagination";
@import "components/error";
@import "components/loader";
@import "components/search";

// 6. Themes
@import "theme/light-theme";
