.rt-pagination {
  .pagination {
    justify-content: center;
    .page-item {
      .page-link {
        height: 45px;
        width: 50px;
        text-align: center;
        line-height: 45px;
        padding: 0;
        margin: 0px 6px !important;
        font-size: 18px;
        font-weight: 600;
        background: #e6eff4;
        border-radius: 5px;
        border: 1px solid transparent;
        color: $colorSecondary;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover,
        &:focus {
          outline: 0;
          box-shadow: none;
          background: $colorPrimary;
          color: $colorWhite;
        }
        span {
          display: inline-flex;
          i {
            display: inline-flex;
          }
        }
      }
    }
  }
}
