// Button
.btn {
  &--common {
    padding: 11px 12px 11px 25px;
    font-size: 16px;
    font-weight: $font-weight-medium;
    background: $colorButton;
    color: $colorSecondary;
    border-radius: 5px;
    outline: 0;
    @include transitionSmooth();
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.01em;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid transparent;
      outline: 0;
      box-shadow: none;
      @include transitionSmooth();
    }
  }
  rect.rt-button-line {
    height: 1.5px;
    y: 7;
  }
  path.rt-button-cap {
    stroke-width: 1;
    stroke: $colorWhite;
  }
  &--hover {
    &:hover,
    &:focus {
      background: $colorPrimary;
      color: $colorWhite;
    }
  }
  &--primary {
    background: $colorPrimary;
    color: $colorWhite;
    &:hover,
    &:focus {
      color: $colorWhite;
    }
  }
  &--white {
    background: $colorWhite;
    color: $colorPrimary;
    &:hover {
      background: $colorWhite;
      color: $colorPrimary;
    }
    path.rt-button-cap {
      stroke: $colorPrimary;
    }
  }
  &--link {
    background: transparent;
    padding: 0;
    font-size: 16px;
    color: $colorPrimary;
    font-weight: 400;
    path.rt-button-cap {
      stroke-width: 1;
      stroke: $colorPrimary;
    }
  }
  &--border {
    background: transparent;
    border: 1px solid rgba($colorWhite, 0.25);
    &:hover,
    &:focus {
      border: 1px solid rgba($colorPrimary, 1);
    }
  }
  &--default {
    padding: 11px 35px;
    &:hover,
    &:focus {
      background: $colorSecondary;
    }
  }
  &--tertiary {
    background: #e8effe;
    color: $colorSecondary;
    &:hover,
    &:focus {
      color: $colorWhite;
    }
  }
  &--secondary {
    background: $colorSecondary;
    color: $colorWhite;
  }
}

// Button Animation Design
.btn--common svg,
.btn--common svg {
  fill: currentColor;
  vertical-align: middle;
  margin-left: 15px;
  width: 26px;
  z-index: 1;
}
.btn--common .rt-button-line {
  stroke: currentColor;
  stroke-width: 0.5;
}
.btn--common .rt-button-cap,
.btn--common .rt-button-line {
  -webkit-transform: translateX(-13px);
  -ms-transform: translateX(-13px);
  transform: translateX(-13px);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  transition: 0.5s;
}
.btn--common:hover .rt-button-line {
  -webkit-animation: qode-button-line 0.4s ease forwards;
  animation: qode-button-line 0.4s ease forwards;
}
.btn--common:hover .rt-button-cap {
  -webkit-animation: qode-button-cap 0.4s ease forwards;
  animation: qode-button-cap 0.4s ease forwards;
}
.rt-button-animation-out .rt-button-cap-fake {
  -webkit-animation: qode-button-cap-fake-out 0.35s linear;
  animation: qode-button-cap-fake-out 0.35s linear;
}
.rt-button-animation-out .rt-button-cap {
  -webkit-animation: qode-button-cap-out 0.35s ease-in-out;
  animation: qode-button-cap-out 0.35s ease-in-out;
}
.rt-button-line {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
  stroke-width: 0.1;
  stroke: currentColor;
}
.rt-button-cap,
.rt-button-line {
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
  -o-transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
  transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
}

@-webkit-keyframes qode-button-cap-out {
  0%,
  50% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
  }
  100%,
  51% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
}
@keyframes qode-button-cap-out {
  0%,
  50% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
  }
  100%,
  51% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
}
@-webkit-keyframes qode-button-cap-fake-out {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  49%,
  51% {
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@keyframes qode-button-cap-fake-out {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  49%,
  51% {
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@-webkit-keyframes qode-button-cap {
  0% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  54% {
    -webkit-transform: translateX(-1px) scaleX(1);
    transform: translateX(-1px) scaleX(1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  55% {
    -webkit-transform: translateX(-20px) scaleX(-1);
    transform: translateX(-20px) scaleX(-1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@keyframes qode-button-cap {
  0% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  54% {
    -webkit-transform: translateX(-1px) scaleX(1);
    transform: translateX(-1px) scaleX(1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  55% {
    -webkit-transform: translateX(-20px) scaleX(-1);
    transform: translateX(-20px) scaleX(-1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}
@-webkit-keyframes qode-button-line {
  0%,
  100% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  10% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  46%,
  55% {
    -webkit-transform: translateX(0) scaleX(0.5);
    transform: translateX(0) scaleX(0.5);
  }
  99% {
    -webkit-transform: translateX(-13px) scaleX(0.6);
    transform: translateX(-13px) scaleX(0.6);
  }
}
@keyframes qode-button-line {
  0%,
  100% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  10% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  46%,
  55% {
    -webkit-transform: translateX(0) scaleX(0.5);
    transform: translateX(0) scaleX(0.5);
  }
  99% {
    -webkit-transform: translateX(-13px) scaleX(0.6);
    transform: translateX(-13px) scaleX(0.6);
  }
}
