// Case Studies
.case-item {
  padding: 0 20px;
  @include transitionSmooth();
  position: relative;

  @include respond-below(xxlg) {
    padding: 0 15px;
  }

  @include respond-below(xlg) {
    padding: 0 15px;
  }

  @include respond-below(lg) {
    padding: 0 15px;
  }

  @include respond-below(xs) {
    padding: 0 15px;
  }

  &__content {
    padding: 15px 15px;
    border-radius: 5px;
    margin: 0 30px;
    margin-top: -40px;
    position: relative;
    z-index: 1;
    @include transitionSmooth();

    @include respond-below(lg) {
      margin: -40px 30px 0 30px;
    }

    @include respond-below(xxlg) {
      margin: -40px 15px 0 15px;
    }

    @include respond-below(xs) {
      margin: -40px 0px 0 0px;
    }

    h5 {
      @include font-size(24px);
      margin-bottom: 2px;
      @include transitionSmooth();
      @include respond-below(xlg) {
        @include font-size(20px);
      }

      &:hover {
        a {
          color: $colorWhite;
        }
      }
    }

    span {
      color: $colorPeriwinkleGray;
      @include transitionSmooth();
    }
  }

  &__image {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);

    a {
      display: block;
      overflow: hidden;

      img {
        transition: 1.5s;
      }
    }
  }

  &:hover {
    .case-item {
      &__image {
        a {
          img {
            transform: scale(1.1);
            transition: 2s;
          }
        }
      }

      &__content {
        background: $colorPrimary;
        @include transitionSmooth();

        h5 {
          color: $colorWhite;
          @include transitionSmooth();
        }

        span {
          color: $colorSnuff;
          @include transitionSmooth();
        }
      }
    }
  }
}

// Case Software
.case-software {
  position: relative;
  padding: 0 0;

  &__image {
    box-shadow: none;
  }

  &__content {
    position: absolute;
    margin: 0 0 0 30px;
    bottom: 17px;
    padding: 19px 193px 19px 30px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    text-align: left !important;
    transform: scale(0.9);
    @include transitionSmooth();

    @include respond-below(lg) {
      margin: 0 0 0 17px;
      padding: 19px 95px 19px 30px;
    }

    @include respond-below(lg) {
      padding: 19px 50px 19px 25px;
    }

    .tags {
      display: block;
    }

    .link {
      @include center(vertical);
      font-size: 16px;
      width: 65px;
      height: 65px;
      line-height: 65px;
      background: #fff;
      text-align: center;
      border-radius: 5px;
      right: 0px;
      @include transitionSmooth();

      &:hover {
        background: $colorSecondary;
        color: $colorWhite;

        i {
          transform: rotate(360deg);
          @include transitionSmooth();
        }
      }
    }
  }

  &:hover {
    .case-software {
      &__content {
        @include transitionSmooth();
        transform: scale(1);
        opacity: 1;
        visibility: visible;

        .link {
          right: -33px;
          @include transitionSmooth();
        }
      }
    }
  }
  &--small {
    .case-software__content {
      margin: 0 0 0 20px;
      bottom: 17px;
      padding: 10px 49px 10px 20px;
      .link {
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
    }
  }
}

.case-choose {
  .image {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    @include overlay($colorPrimary, 0.7);
    @include transitionSmooth();

    &::after {
      opacity: 0;
      visibility: hidden;
      @include transitionSmooth();
    }

    a {
      display: inline-block;
      width: 100%;

      img {
        @include transitionSmooth();
      }
    }
  }

  &__content {
    padding-top: 23px;

    .paragraph {
      margin-bottom: 25px;
      padding-right: 50px;
      @include respond-below(lg) {
        padding-right: 0;
      }
      @include respond-below(md) {
        padding-right: 0;
      }
    }

    .link {
      font-size: 16px;
      font-weight: $font-weight-medium;
      color: $colorPrimary;

      &:hover {
        color: $colorSecondary;
        padding-left: 15px;
      }
    }
  }

  &:hover {
    .image {
      a {
        img {
          transform: scale(1.3);
          @include transitionSmooth();
        }
      }

      &::after {
        opacity: 1;
        visibility: visible;
        @include transitionSmooth();
      }
    }
    .case-choose {
      &__content {
        .heading {
          a {
            color: $colorPrimary;
          }
        }
      }
    }
  }
}

.case-details {
  &__header {
    padding: 0 70px;
    @include respond-below(sm) {
      padding: 0 0px;
    }
    ul {
      margin-bottom: 38px;
      li {
        display: inline-flex;
        align-items: center;
        margin: 0 15px;
        font-size: 14px;
        color: $colorText;
        span {
          @include transitionSmooth();
          a {
            color: $colorText;
            &:hover {
              color: $colorSecondary;
            }
          }
        }
        i {
          font-size: 13px;
          margin-right: 10px;
        }
        &:hover {
          span {
            color: $colorSecondary;
            @include transitionSmooth();
          }
        }
      }
    }
  }
  &__row {
    margin-right: -10px;
    margin-left: -10px;
    .item {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  &__gallery {
    border-radius: 5px;
    overflow: hidden;
  }
  &__article {
    .heading {
      max-width: 636px;
      font-size: 35px;
      text-align: center;
      padding: 0 15px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
    .paragraph {
      padding: 0 86px;
      text-align: center;
      margin-bottom: 25px;
      @include respond-below(sm) {
        padding: 0 0px;
      }
    }
  }
  &__video {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    .video {
      @include center-position();
      .video-link {
        height: 100px;
        width: 100px;
        line-height: 100px;
        font-size: 24px;
        @include respond-below(xs) {
          height: 60px;
          width: 60px;
          line-height: 60px;
          font-size: 16px;
        }
      }
    }
  }
  &__social {
    .header-top__social {
      text-align: left;
      padding: 30px 0;
      @include respond-below(sm) {
        text-align: center;
      }
      ul {
        li {
          margin-left: 0px;
          margin-right: 7px;
        }
      }
    }
    .case-instagram-hit {
      text-align: right;
      @include respond-below(sm) {
        text-align: center;
      }
      p {
        font-size: 20px;
        text-align: right;
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
        cursor: pointer;
        i {
          margin-right: 13px;
        }
      }
    }
  }
  &__mover {
    padding: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    .row {
      justify-content: space-between;
      align-items: center;
    }
    .mover {
      &--left {
        @include respond-below(sm) {
          text-align: center;
        }
      }
      .link {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: $font-weight-medium;
        color: $colorSecondary;
        i {
          display: inline-flex;
          margin-right: 13px;
          @include transitionSmooth();
        }
        h3 {
          font-size: 20px;
          font-weight: $font-weight-semibold;
          margin-bottom: 0;
          padding-right: 23px;
          @include transitionSmooth();
        }
        img {
          border-radius: 5px;
        }
        &:hover {
          color: $colorPrimary;
          i,
          h3 {
            color: $colorPrimary;
            @include transitionSmooth();
          }
        }
      }
      &--middle {
        text-align: center;
        .link {
          i {
            margin-right: 0;
            font-size: 20px;
            color: $colorText;
          }
        }
      }
      &--last {
        text-align: right;
        @include respond-below(sm) {
          text-align: center;
        }
      }
    }
  }
  &__related {
    padding-top: 95px;
  }
}

.case-modern {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &::before {
    background-color: rgba($colorSecondary, 0.75);
    transform: scale3d(0.8, 0.8, 0.8);
    transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  &::after {
    border: 2px solid rgba($colorWhite, 0.16);
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale3d(1, 1, 1);
    border-radius: 5px;
    z-index: 2;
    transition: all 1.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    opacity: 0;
    visibility: hidden;
  }
  &__image {
    .link {
      position: absolute;
      bottom: 0px;
      left: 50%;
      display: inline-block;
      justify-content: center;
      background: $colorWhite;
      transform: translateX(-50%);
      font-size: 16px;
      font-weight: 500;
      color: $colorPrimary;
      padding: 13px 32px;
      border-radius: 5px 5px 0 0;
      z-index: 4;
      opacity: 0;
      visibility: hidden;
      transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
      &:hover {
        background-color: $colorPrimary;
        color: $colorWhite;
      }
    }
  }
  &__content {
    @include center(vertical);
    right: 0;
    left: 0;
    text-align: center;
    z-index: 3;
    overflow: hidden;
    .tags {
      font-size: 18px;
      font-weight: $font-weight-medium;
      font-family: $font-family-secondary;
      color: #fbfcff;
      display: block;
      margin-bottom: 8px;
      & > span {
        transform: translateY(-30px);
        display: inline-block;
        transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
      }
    }
    .heading {
      text-transform: uppercase;
      letter-spacing: 0.3em;
      & > a {
        transform: translateY(35px);
        display: inline-block;
        transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
        color: $colorWhite;
        &:hover {
          color: $colorWhite;
        }
      }
    }
  }
  &:hover {
    transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    &::before {
      transform: scale3d(1.5, 1.5, 1.5);
      transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
      opacity: 1;
      visibility: visible;
    }
    &::after {
      transform: translateY(-50%) translateX(-50%) scale3d(0.85, 0.79, 0.85);
      transition: all 1.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
      opacity: 1;
      visibility: visible;
    }
    .case-modern {
      &__image {
        .link {
          opacity: 1;
          visibility: visible;
          bottom: 45px;
          transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
        }
      }
      &__content {
        .tags {
          & > span {
            transform: translateY(0px);
            transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
          }
        }
        .heading {
          & > a {
            transform: translateY(0px);
            transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
          }
        }
      }
    }
  }
}

.case-menu {
  margin-bottom: 40px;
  text-align: center;
  & button {
    font-size: 18px;
    font-family: $font-family-primary;
    color: $colorText;
    background: transparent;
    border: 0;
    padding: 0;
    margin-right: 25px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: $colorPrimary;
    }
  }
}

.case-block {
  &--startup {
    .item-overlay {
      background: rgba($colorPrimary, 0.8);
    }
  }
}
