/*
	*******************

		Template name:	Techkit - Technology Bootstrap 5 Template
		Version:        1.0
		Author:         RadiusTheme
		Author url:     https://themeforest.net/user/radiustheme

	*******************


***** CSS INDEX *****
********************/
/*-- Margin Top --*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.fs-1 {
  font-size: 1px;
}

.fs-2 {
  font-size: 2px;
}

.fs-3 {
  font-size: 3px;
}

.fs-4 {
  font-size: 4px;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.fs-50 {
  font-size: 50px;
}

.fs-51 {
  font-size: 51px;
}

.fs-52 {
  font-size: 52px;
}

.fs-53 {
  font-size: 53px;
}

.fs-54 {
  font-size: 54px;
}

.fs-55 {
  font-size: 55px;
}

.fs-56 {
  font-size: 56px;
}

.fs-57 {
  font-size: 57px;
}

.fs-58 {
  font-size: 58px;
}

.fs-59 {
  font-size: 59px;
}

.fs-60 {
  font-size: 60px;
}

.fs-61 {
  font-size: 61px;
}

.fs-62 {
  font-size: 62px;
}

.fs-63 {
  font-size: 63px;
}

.fs-64 {
  font-size: 64px;
}

.fs-65 {
  font-size: 65px;
}

.fs-66 {
  font-size: 66px;
}

.fs-67 {
  font-size: 67px;
}

.fs-68 {
  font-size: 68px;
}

.fs-69 {
  font-size: 69px;
}

.fs-70 {
  font-size: 70px;
}

.fs-71 {
  font-size: 71px;
}

.fs-72 {
  font-size: 72px;
}

.fs-73 {
  font-size: 73px;
}

.fs-74 {
  font-size: 74px;
}

.fs-75 {
  font-size: 75px;
}

.fs-76 {
  font-size: 76px;
}

.fs-77 {
  font-size: 77px;
}

.fs-78 {
  font-size: 78px;
}

.fs-79 {
  font-size: 79px;
}

.fs-80 {
  font-size: 80px;
}

.fs-81 {
  font-size: 81px;
}

.fs-82 {
  font-size: 82px;
}

.fs-83 {
  font-size: 83px;
}

.fs-84 {
  font-size: 84px;
}

.fs-85 {
  font-size: 85px;
}

.fs-86 {
  font-size: 86px;
}

.fs-87 {
  font-size: 87px;
}

.fs-88 {
  font-size: 88px;
}

.fs-89 {
  font-size: 89px;
}

.fs-90 {
  font-size: 90px;
}

.fs-91 {
  font-size: 91px;
}

.fs-92 {
  font-size: 92px;
}

.fs-93 {
  font-size: 93px;
}

.fs-94 {
  font-size: 94px;
}

.fs-95 {
  font-size: 95px;
}

.fs-96 {
  font-size: 96px;
}

.fs-97 {
  font-size: 97px;
}

.fs-98 {
  font-size: 98px;
}

.fs-99 {
  font-size: 99px;
}

.fs-100 {
  font-size: 100px;
}

.fs-101 {
  font-size: 101px;
}

.fs-102 {
  font-size: 102px;
}

.fs-103 {
  font-size: 103px;
}

.fs-104 {
  font-size: 104px;
}

.fs-105 {
  font-size: 105px;
}

.fs-106 {
  font-size: 106px;
}

.fs-107 {
  font-size: 107px;
}

.fs-108 {
  font-size: 108px;
}

.fs-109 {
  font-size: 109px;
}

.fs-110 {
  font-size: 110px;
}

.fs-111 {
  font-size: 111px;
}

.fs-112 {
  font-size: 112px;
}

.fs-113 {
  font-size: 113px;
}

.fs-114 {
  font-size: 114px;
}

.fs-115 {
  font-size: 115px;
}

.fs-116 {
  font-size: 116px;
}

.fs-117 {
  font-size: 117px;
}

.fs-118 {
  font-size: 118px;
}

.fs-119 {
  font-size: 119px;
}

.fs-120 {
  font-size: 120px;
}

.fs-121 {
  font-size: 121px;
}

.fs-122 {
  font-size: 122px;
}

.fs-123 {
  font-size: 123px;
}

.fs-124 {
  font-size: 124px;
}

.fs-125 {
  font-size: 125px;
}

.fs-126 {
  font-size: 126px;
}

.fs-127 {
  font-size: 127px;
}

.fs-128 {
  font-size: 128px;
}

.fs-129 {
  font-size: 129px;
}

.fs-130 {
  font-size: 130px;
}

.fs-131 {
  font-size: 131px;
}

.fs-132 {
  font-size: 132px;
}

.fs-133 {
  font-size: 133px;
}

.fs-134 {
  font-size: 134px;
}

.fs-135 {
  font-size: 135px;
}

.fs-136 {
  font-size: 136px;
}

.fs-137 {
  font-size: 137px;
}

.fs-138 {
  font-size: 138px;
}

.fs-139 {
  font-size: 139px;
}

.fs-140 {
  font-size: 140px;
}

.fs-141 {
  font-size: 141px;
}

.fs-142 {
  font-size: 142px;
}

.fs-143 {
  font-size: 143px;
}

.fs-144 {
  font-size: 144px;
}

.fs-145 {
  font-size: 145px;
}

.fs-146 {
  font-size: 146px;
}

.fs-147 {
  font-size: 147px;
}

.fs-148 {
  font-size: 148px;
}

.fs-149 {
  font-size: 149px;
}

.fs-150 {
  font-size: 150px;
}

.fs-151 {
  font-size: 151px;
}

.fs-152 {
  font-size: 152px;
}

.fs-153 {
  font-size: 153px;
}

.fs-154 {
  font-size: 154px;
}

.fs-155 {
  font-size: 155px;
}

.fs-156 {
  font-size: 156px;
}

.fs-157 {
  font-size: 157px;
}

.fs-158 {
  font-size: 158px;
}

.fs-159 {
  font-size: 159px;
}

.fs-160 {
  font-size: 160px;
}

.fs-161 {
  font-size: 161px;
}

.fs-162 {
  font-size: 162px;
}

.fs-163 {
  font-size: 163px;
}

.fs-164 {
  font-size: 164px;
}

.fs-165 {
  font-size: 165px;
}

.fs-166 {
  font-size: 166px;
}

.fs-167 {
  font-size: 167px;
}

.fs-168 {
  font-size: 168px;
}

.fs-169 {
  font-size: 169px;
}

.fs-170 {
  font-size: 170px;
}

.fs-171 {
  font-size: 171px;
}

.fs-172 {
  font-size: 172px;
}

.fs-173 {
  font-size: 173px;
}

.fs-174 {
  font-size: 174px;
}

.fs-175 {
  font-size: 175px;
}

.fs-176 {
  font-size: 176px;
}

.fs-177 {
  font-size: 177px;
}

.fs-178 {
  font-size: 178px;
}

.fs-179 {
  font-size: 179px;
}

.fs-180 {
  font-size: 180px;
}

.fs-181 {
  font-size: 181px;
}

.fs-182 {
  font-size: 182px;
}

.fs-183 {
  font-size: 183px;
}

.fs-184 {
  font-size: 184px;
}

.fs-185 {
  font-size: 185px;
}

.fs-186 {
  font-size: 186px;
}

.fs-187 {
  font-size: 187px;
}

.fs-188 {
  font-size: 188px;
}

.fs-189 {
  font-size: 189px;
}

.fs-190 {
  font-size: 190px;
}

.fs-191 {
  font-size: 191px;
}

.fs-192 {
  font-size: 192px;
}

.fs-193 {
  font-size: 193px;
}

.fs-194 {
  font-size: 194px;
}

.fs-195 {
  font-size: 195px;
}

.fs-196 {
  font-size: 196px;
}

.fs-197 {
  font-size: 197px;
}

.fs-198 {
  font-size: 198px;
}

.fs-199 {
  font-size: 199px;
}

.fs-200 {
  font-size: 200px;
}

/*-- Usage --*/
.sect-mast:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.sect-mast .row,
.sect-mast .inner {
  position: relative;
  z-index: 2;
}

/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-primary: #0554f2;
  --bs-secondary: #14133b;
  --bs-white: #ffffff;
  --bs-black: #000000;
  --bs-text: #5b6674;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #e1e1e1;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #bdbdbd;
  --bs-gray-600: #868686;
  --bs-gray-700: #575757;
  --bs-gray-800: #343a40;
  --bs-gray-900: #313131;
  --bs-primary: #0d6efd;
  --bs-secondary: #868686;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #313131;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 134, 134, 134;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 49, 49, 49;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 49, 49, 49;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #313131;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #313131;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #868686;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #868686;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #868686;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #313131;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #313131;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #313131;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #313131;
  vertical-align: top;
  border-color: #e1e1e1;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e7e7e7;
  --bs-table-striped-bg: #dbdbdb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d0d0d0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d6d6d6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d0d0d0;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #313131;
  --bs-table-striped-bg: #3b3b3b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #464646;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #404040;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #464646;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #868686;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #313131;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #868686;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #313131;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #313131;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #313131;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #313131;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e1e1;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e1e1e1;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #bdbdbd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #bdbdbd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #313131;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #000;
  background-color: #868686;
  border-color: #868686;
}

.btn-secondary:hover {
  color: #000;
  background-color: #989898;
  border-color: #929292;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #989898;
  border-color: #929292;
  box-shadow: 0 0 0 0.25rem rgba(114, 114, 114, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #9e9e9e;
  border-color: #929292;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(114, 114, 114, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #868686;
  border-color: #868686;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #313131;
  border-color: #313131;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #272727;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #272727;
  box-shadow: 0 0 0 0.25rem rgba(80, 80, 80, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #272727;
  border-color: #252525;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 80, 80, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #313131;
  border-color: #313131;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #868686;
  border-color: #868686;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #868686;
  border-color: #868686;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(134, 134, 134, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #868686;
  border-color: #868686;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(134, 134, 134, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #868686;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #313131;
  border-color: #313131;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #313131;
  border-color: #313131;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 49, 49, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #313131;
  border-color: #313131;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 49, 49, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #313131;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link:disabled, .btn-link.disabled {
  color: #868686;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #313131;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #313131;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #2c2c2c;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #bdbdbd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868686;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #313131;
}

.dropdown-menu-dark {
  color: #e1e1e1;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #e1e1e1;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #bdbdbd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #e1e1e1;
}

.dropdown-menu-dark .dropdown-header {
  color: #bdbdbd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}

.nav-link.disabled {
  color: #868686;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e1e1e1;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #e1e1e1;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #868686;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #575757;
  background-color: #fff;
  border-color: #e1e1e1 #e1e1e1 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .sidebar-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .sidebar-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .sidebar-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .sidebar-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .sidebar-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .sidebar-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}

.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #313131;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23313131'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #868686;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #868686;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #e1e1e1;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-item.disabled .page-link {
  color: #868686;
  pointer-events: none;
  background-color: #fff;
  border-color: #e1e1e1;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #505050;
  background-color: #e7e7e7;
  border-color: #dbdbdb;
}

.alert-secondary .alert-link {
  color: #404040;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #1d1d1d;
  background-color: #d6d6d6;
  border-color: #c1c1c1;
}

.alert-dark .alert-link {
  color: #171717;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #575757;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #575757;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #313131;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #313131;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #868686;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #505050;
  background-color: #e7e7e7;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #505050;
  background-color: #d0d0d0;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #1d1d1d;
  background-color: #d6d6d6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1d1d1d;
  background-color: #c1c1c1;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #1d1d1d;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 35px 0px rgba(87, 87, 87, 0.05);
  border-radius: 0.25rem;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #868686;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1e1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e1e1;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #313131;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}

.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #868686;
}

.link-secondary:hover, .link-secondary:focus {
  color: #9e9e9e;
}

.link-success {
  color: #198754;
}

.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #313131;
}

.link-dark:hover, .link-dark:focus {
  color: #272727;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0px 8px 35px 0px rgba(87, 87, 87, 0.05) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e1e1e1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e1e1e1 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #e1e1e1 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1e1 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #e1e1e1 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #868686 !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #313131 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #868686 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/**
@include respond-above(sm) {
  .element {
    font-weight: bold;
  }
}

@media (min-width: 768px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-below(sm) {
  .element {
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  .element {
    font-weight: bold;
  }
}
**/
/**
@include respond-between(sm, md) {
  .element {
    font-weight: bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .element {
    font-weight: bold;
  }
}
**/
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%;
}

html,
body {
  min-height: 100%;
}

h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

i {
  font-style: normal;
}

figure {
  margin: 0;
  padding: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

a,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #0554f2;
}

a:hover {
  text-decoration: none;
  outline: none;
  color: #0554f2;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

label {
  color: #14133b;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
}

body {
  font-family: "Barlow", sans-serif;
  color: #14133b;
  font-size: 16px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #5b6674;
  font-size: 16px;
  line-height: 1.75;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Barlow", sans-serif;
  color: #14133b;
  line-height: 1.4;
  font-weight: 600;
}

button {
  cursor: pointer;
}

button.button {
  background: transparent;
  border: 0px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

::-moz-selection {
  background: #313131;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #313131;
  color: #ffffff;
  text-shadow: none;
}

section {
  position: relative;
}

.container-custom {
  padding-left: 185px;
  padding-right: 185px;
}

@media (max-width: 1666px) {
  .container-custom {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .container-custom {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1199px) {
  .container-custom {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.primary-text-color {
  color: #0554f2;
}

.secondary-text-color {
  color: #14133b;
}

.primary-bg-color {
  background: #0554f2;
}

.secondary-bg-color {
  background: #14133b;
}

.section-bg-common {
  background: #f5faff;
}

.white-bg {
  background: #ffffff;
}

.gray-bg {
  background: #f8f9fa;
}

.section-modern-bg {
  background: #f5f8ff;
}

.section-startup-bg {
  background: #f7faff;
}

h1, .h1 {
  font-size: calc(1.38125rem + 1.575vw);
  font-weight: 400;
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5625rem;
  }
}

h2, .h2 {
  font-size: calc(1.32775rem + 0.933vw);
  line-height: 1.33;
  color: #14133b;
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.0275rem;
  }
}

h3, .h3 {
  font-size: 25.63px;
}

@media (max-width: 1199px) {
  h3, .h3 {
    font-size: 20px;
  }
}

h4, .h4 {
  font-size: 20.25px;
}

@media (max-width: 1199px) {
  h4, .h4 {
    font-size: 18px;
  }
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.border-white-bottom {
  border-bottom: 1px solid #ffffff;
}

.section-button {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .section-button {
    justify-content: flex-start;
  }
}

.animted-bg-wrap:hover .animted-bg {
  width: 1500px;
  height: 1500px;
}

.animted-bg-wrap .animted-bg {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(5, 84, 242, 0.9);
  transition: width 1s ease-in-out, height 1s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.g-10 > * {
  padding-right: calc(var(--bs-gutter-x) / 2.4);
  padding-left: calc(var(--bs-gutter-x) / 2.4);
}

.title--related {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .title--related {
    font-size: 1.875rem;
  }
}

.title--blog {
  font-size: 22px;
  font-weight: 600;
}

.form-response .alert-danger,
.form-response .alert.alert-info,
.form-response .alert.alert-success {
  padding: 10px 20px;
}

.form-response .alert-danger p,
.form-response .alert.alert-info p,
.form-response .alert.alert-success p {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    animation: none !important;
  }
}

@keyframes morph {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    opacity: 0.89;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

.main-menu__nav ul li {
  display: inline-block;
  align-items: center;
  position: relative;
  padding: 0 0px 0 17px;
}

.main-menu__nav ul li:hover .main-menu__dropdown {
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  visibility: visible;
  top: 100%;
  transform: scaleY(1);
}

.main-menu__nav ul li a {
  font-size: 17px;
  color: #14133b;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding: 33px 6px;
}

.main-menu__nav ul li a::before {
  --scale: 0;
  position: absolute;
  content: "";
  height: 4px;
  background: #0554f2;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  transform: scaleX(var(--scale));
  transform-origin: var(--x) 50%;
  transition: transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.main-menu__nav ul li:hover > a {
  color: #0554f2;
}

.main-menu__nav ul li:hover > a::before {
  --scale: 1;
  background: #0554f2;
}

.main-menu__nav ul li ul {
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.3, 0.02, 0, 0.6);
  opacity: 0;
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  margin: 0;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  border-radius: 0 0 5px 5px;
  transform: scaleY(0);
  transform-origin: 0 0 0;
}

.main-menu__nav ul li ul li {
  display: block !important;
  padding: 0 17px;
}

.main-menu__nav ul li ul li a {
  display: block !important;
  font-size: 15px;
  padding: 8.5px 10px;
  color: #656567;
  text-transform: capitalize;
  font-weight: 500;
}

.main-menu__nav ul li ul li a::after {
  opacity: 0;
  display: none;
}

.main-menu__nav ul li ul li a::before {
  width: 3px;
  height: 0px;
  top: 50%;
  transform: translateY(-50%);
  left: 0 !important;
  margin: 0;
  background: #6c6c6c;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.main-menu__nav ul li ul li:hover a::before {
  height: 11px;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.main-menu__nav_sub a::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  vertical-align: middle;
  transform: rotate(0deg);
  display: inline-block;
  padding-left: 6px;
  font-size: 12px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.main-menu__nav_sub:hover a::after {
  transform: rotate(180deg) translateX(-6px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.main-menu--light nav ul li a {
  color: #ffffff;
}

.offscreen-navigation .sub-menu {
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
}

.offscreen-navigation li > a {
  display: block;
  position: relative;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.offscreen-navigation .sub-menu li > a {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5.5px 0px;
  color: #7d7d7e;
}

.offscreen-navigation .sub-menu li > a:hover {
  color: #0554f2;
}

.offscreen-navigation .menu > li {
  padding-bottom: 8px;
}

.offscreen-navigation .menu > li a.animation.opened {
  color: #0554f2;
}

.offscreen-navigation li.menu-item-has-children > a:after {
  content: "\f067";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  transition: 0.1s;
  right: 0;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
}

.offscreen-navigation li.menu-item-has-children > a.opened:after {
  content: "\f068";
}

.rt-slide-nav {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  display: none;
  padding: 30px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  box-shadow: 0 0 10px 0 #0000001c;
  top: 100%;
  z-index: 999;
}

.rt-header-menu {
  padding: 21px 15px;
  border-bottom: 1px solid rgba(81, 81, 81, 0.15);
}

@media (min-width: 992px) {
  .rt-header-menu {
    display: none;
  }
}

.mean-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-menu--agency nav > ul > li {
  margin-right: 30px;
}

.main-menu--agency nav > ul > li:first-child {
  padding-left: 0;
}

@media (max-width: 1199px) {
  .main-menu--agency nav > ul > li {
    margin-right: 15px;
  }
}

.main-menu--agency nav > ul > li a {
  padding-left: 0;
  padding-right: 0;
  color: #ffffff;
}

.main-menu--agency nav > ul > li a::before {
  height: 1px;
}

.main-menu--modern {
  padding-left: 150px;
}

@media (max-width: 1666px) {
  .main-menu--modern {
    padding-left: 50px;
  }
}

.main-menu--startup > nav > ul > li {
  padding: 0 0px 0 30px;
}

.logo {
  position: relative;
}

.logo__sticky {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.sticky-on.sticky .logo__sticky {
  opacity: 1;
  visibility: visible;
}

.header-top__padding {
  padding-top: 16.5px;
  padding-bottom: 16.5px;
}

.header-top__border_bottom {
  border-bottom: 1px solid #e1e1e1;
}

.header-top__info {
  display: flex;
  align-items: center;
}

.header-top__info_item {
  display: flex;
  align-items: center;
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid #d4d3d3;
}

@media (max-width: 1199px) {
  .header-top__info_item {
    padding-right: 35px;
    margin-right: 35px;
  }
}

.header-top__info_item:last-child {
  padding-right: 0px;
  margin-right: 0px;
  border-right: 1px solid transparent;
}

.header-top__info_icon {
  line-height: 0;
}

.header-top__info_icon i {
  line-height: 0;
  display: inline-block;
  margin-right: 15px;
}

.header-top__info_icon i::before {
  font-size: 27px;
  color: #0554f2;
}

.header-top__info_text--small {
  color: #76767d;
  font-size: 13px;
  margin-bottom: 0;
}

.header-top__info_text--big {
  font-size: 15px;
  font-weight: 500;
  color: #14133b;
  font-family: "Barlow", sans-serif;
}

@media (max-width: 1366px) {
  .header-top__info_address {
    display: none;
  }
}

.header-top__social {
  text-align: right;
}

.header-top__social ul {
  display: inline-flex;
}

.header-top__social_list {
  margin-left: 7px;
}

.header-top__social_list--link {
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  background: transparent;
  border-radius: 7px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header-top__social_list--link i {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header-top__social_list .facebook {
  background: #edf3ff;
}

.header-top__social_list .facebook i {
  color: #4064ac;
}

.header-top__social_list .facebook:hover {
  background: #4064ac;
}

.header-top__social_list .facebook:hover i {
  color: #ffffff;
}

.header-top__social_list .twitter {
  background: #dff3ff;
}

.header-top__social_list .twitter i {
  color: #1c9cea;
}

.header-top__social_list .twitter:hover {
  background: #1c9cea;
}

.header-top__social_list .twitter:hover i {
  color: #ffffff;
}

.header-top__social_list .instagram {
  background: #ffedf5;
}

.header-top__social_list .instagram i {
  color: #d1005c;
}

.header-top__social_list .instagram:hover {
  background: #d1005c;
}

.header-top__social_list .instagram:hover i {
  color: #ffffff;
}

.header-top__social_list .linkedin {
  background: #ecf8ff;
}

.header-top__social_list .linkedin i {
  color: #016196;
}

.header-top__social_list .linkedin:hover {
  background: #016196;
}

.header-top__social_list .linkedin:hover i {
  color: #ffffff;
}

.header-top__social_list .pinterest {
  background: #fff1f3;
}

.header-top__social_list .pinterest i {
  color: #df0022;
}

.header-top__social_list .pinterest:hover {
  background: #df0022;
}

.header-top__social_list .pinterest:hover i {
  color: #ffffff;
}

.header-top--bg {
  background-color: #f6f9ff;
}

.header-top__startup {
  display: flex;
}

.header-top__startup .header-top__info_icon > i::before {
  font-size: 18px;
  color: #5b6674;
}

.header-top__startup .header-top__info_item {
  margin-right: 0;
  border-right: 0px solid transparent;
}

.header-top__startup .header-top__info_text--big {
  color: #5b6674;
  font-family: "Barlow", sans-serif;
}

.header-bottom__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-search {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 10px 22px;
  width: 330px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 45px;
}

.header-search__input {
  border: 1px solid transparent;
  width: 100%;
  background: transparent;
}

.header-search__button {
  font-size: 15px;
  background: transparent;
  color: #14133b;
  border: 1px solid transparent;
  font-size: 15px;
  right: 22px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1366px) {
  .header-search {
    display: none;
  }
}

@media (max-width: 991px) {
  .header-search {
    display: block;
  }
}

@media (max-width: 767px) {
  .header-search {
    display: none;
  }
}

@media (max-width: 575px) {
  .header-search {
    margin: 0 15px;
    width: 160px;
    display: none;
  }
}

.header--transparrent {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 9999;
  border-bottom: 1px solid rgba(81, 81, 81, 0.15);
  background: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header--software {
  background: #ffffff !important;
}

.header-bottom__software_row {
  justify-content: start;
}

.header-bottom__software_row .main-menu {
  padding-left: 286px;
}

@media (max-width: 1366px) {
  .header-bottom__software_row .main-menu {
    padding-left: 30px;
  }
}

.header-bottom__software_row .main-menu ul li a::before {
  height: 1px;
  bottom: -1px;
  z-index: 999;
}

.header--agency {
  border-bottom: 1px solid transparent;
  background: transparent;
}

.header-top--padding {
  padding: 20px 0;
}

.header-top--border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.header-top--agency .logo {
  text-align: center;
}

.header-top--agency .header-top__info_address {
  justify-content: flex-end;
}

.header-top--agency .info i {
  display: inline-block;
}

.header-top--agency .info i::before {
  font-size: 19.82px;
  color: #ffffff;
}

.header-top--agency .info span {
  color: #ffffff;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.header--vertical {
  background: transparent;
  padding: 0 105px;
  border-bottom: 1px solid transparent;
}

@media (max-width: 767px) {
  .header--vertical {
    padding: 0 0;
  }
}

.header--vertical .logo {
  padding: 60px 0;
}

@media (max-width: 767px) {
  .header--vertical .logo {
    padding: 15px 0;
  }
}

.header-right--agency ul {
  text-align: right;
}

.header-right--agency ul li {
  display: inline-block;
  margin-left: 25px;
}

.header-right--agency ul li .search a {
  color: #ffffff;
  font-size: 15px;
}

.header-right--agency ul li .search--circle > a {
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
}

.header-right--agency ul li .search--circle > a:hover {
  background: white;
  color: #0554f2;
}

.header-right--agency ul li .search--startup > a {
  background: rgba(5, 84, 242, 0.1);
  color: #0554f2;
}

.header--inner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.header--inner.IsSticky .logo__static {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header--inner.IsSticky .logo__sticky {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header--modern {
  transition: background 1s ease, padding 0.8s linear;
  background-color: transparent;
}

.header--modern .logo__dark {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header--modern.sticky-on.sticky .logo__dark {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.header--modern.sticky-on.sticky .logo__light {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.slider__bg {
  height: calc(100vh - 170px);
  display: flex !important;
  align-items: center;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.slider__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.5);
  z-index: 0;
}

.slider__bg .row,
.slider__bg .inner {
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .slider__bg {
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 1199px) {
  .slider__bg {
    background-position: center;
  }
}

@media (max-width: 1366px) {
  .slider__bg {
    padding-right: 0;
  }
}

.slider__bg--startup {
  height: calc(100vh - 147px);
}

.slider__bg--startup:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(5, 84, 242, 0.31);
  z-index: 0;
}

.slider__bg--startup .row,
.slider__bg--startup .inner {
  position: relative;
  z-index: 2;
}

@media (max-width: 1199px) {
  .slider__bg--startup {
    background-position: center top;
  }
}

@media (max-width: 991px) {
  .slider__bg--startup {
    height: 100%;
  }
}

.slider-content__title_small {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1em;
  position: relative;
  line-height: 1;
  display: inline-block;
  padding-left: 15px;
  margin-bottom: 14px;
}

@media (max-width: 575px) {
  .slider-content__title_small {
    font-size: 14px;
  }
}

.slider-content__title_small::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 5px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #0554f2;
}

.slider-content__title_big {
  animation-delay: 0.4s;
  font-size: calc(1.5rem + 3vw);
  color: #fff;
  text-decoration: underline;
  line-height: 1.15;
  font-weight: 600;
  text-decoration-thickness: 4px;
  text-underline-position: under;
  text-underline-offset: -4px;
  margin-bottom: 46px;
  max-width: 570px;
}

@media (min-width: 1200px) {
  .slider-content__title_big {
    font-size: 3.75rem;
  }
}

.slider-content__text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 50px;
  max-width: 570px;
}

@media (max-width: 1366px) {
  .slider-content__text {
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .slider-content__text {
    padding-right: 0;
  }
}

.slider-content--two .title--big {
  font-size: calc(1.5rem + 3vw);
  font-weight: 700;
  margin-bottom: 30px;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .slider-content--two .title--big {
    font-size: 3.75rem;
  }
}

.slider-content--startup .slider-content__title_small {
  padding-left: 0;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px dashed #fff;
  padding: 14px 22px;
  border-radius: 5px;
  margin-bottom: 30px;
  display: inline-block;
}

.slider-content--startup .slider-content__title_small::before {
  display: none;
}

.slider-content--startup .slider-content__title_big {
  font-size: calc(1.5rem + 3vw);
  text-decoration: none;
  margin-bottom: 36px;
}

@media (min-width: 1200px) {
  .slider-content--startup .slider-content__title_big {
    font-size: 3.75rem;
  }
}

.slider-content--startup .slider-content__text {
  font-size: 20px;
  padding-right: 100px;
}

@media (max-width: 991px) {
  .slider-content--startup .slider-content__text {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .slider-content--startup .slider-content__call {
    display: block;
    padding-top: 15px;
  }
}

.slider-content--startup .slider-content__call > a {
  font-size: 18px;
  color: #ffffff;
  padding-left: 30px;
}

@media (max-width: 767px) {
  .slider-content--startup .slider-content__call > a {
    padding-left: 0;
  }
}

.slider-content--startup .slider-content__call > a > strong {
  font-weight: 500;
}

.slider--active .slick-track:focus {
  outline: 0;
}

.slider--active .slick-dots {
  text-align: center;
  line-height: 0;
  margin-top: -96px;
  z-index: 1;
  position: absolute;
  right: 0;
  left: 0;
}

.slider--active .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  line-height: 0;
}

.slider--active .slick-dots li button {
  text-indent: -99999px;
  width: 20px;
  height: 8px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: transparent;
}

.slider--active .slick-dots .slick-active button {
  width: 30px;
  background: transparent;
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: #ffffff;
}

.slider__shape {
  position: absolute;
  z-index: 1;
}

.slider__shape--one {
  right: 296px;
  top: 193px;
}

@media (max-width: 1199px) {
  .slider__shape--one {
    right: 196px;
  }
}

@media (max-width: 575px) {
  .slider__shape--one {
    display: none !important;
  }
}

.slider__shape--two {
  right: 0;
  top: 0;
}

@media (max-width: 1199px) {
  .slider__shape--two {
    display: none !important;
  }
}

.slider__shape--three {
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 293px;
}

.slider__shape--four {
  bottom: -314px;
  right: 243px;
}

.slider__shape--five {
  bottom: 93px;
  left: 342px;
}

.slider__shape--six {
  bottom: -257px;
  left: 0px;
}

.hero--bg__software {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero--padding__software {
  padding-left: 185px;
  padding-right: 40px;
  padding-top: 250px;
  padding-bottom: 95px;
}

@media (max-width: 1666px) {
  .hero--padding__software {
    padding-left: 60px;
    padding-right: 0px;
    padding-top: 200px;
  }
}

@media (max-width: 991px) {
  .hero--padding__software {
    padding-left: 0;
    padding-right: 0;
    padding-top: 113px;
  }
}

@media (max-width: 575px) {
  .hero--padding__software {
    padding-top: 65px;
    padding-bottom: 45px;
  }
}

.hero-img {
  display: inline-block;
  position: relative;
}

@media (max-width: 1666px) {
  .hero-img {
    margin-left: -65px;
    margin-right: -30%;
  }
}

@media (max-width: 1199px) {
  .hero-img {
    margin-left: 0;
    margin-right: 0%;
  }
}

@media (max-width: 991px) {
  .hero-img {
    margin-left: -65px;
    margin-right: -30%;
  }
}

@media (max-width: 575px) {
  .hero-img {
    margin-right: 0%;
    margin-left: 0;
  }
}

.hero-img__animation_list {
  position: absolute;
  height: 608px;
  width: 100%;
  top: 0;
}

@media (max-width: 1199px) {
  .hero-img__animation_list {
    display: none;
  }
}

@media (max-width: 991px) {
  .hero-img__animation_list {
    display: block;
  }
}

@media (max-width: 575px) {
  .hero-img__animation_list {
    display: none;
  }
}

.hero-img--animation .item {
  display: inline-block;
  position: absolute;
}

.hero-img--animation .item-1 {
  bottom: -5px;
  left: 33px;
}

.hero-img--animation .item-2 {
  bottom: -5px;
  right: 208px;
}

.hero-img--animation .item-3 {
  bottom: 225px;
  right: -25px;
}

.hero-img--animation .item-4 {
  top: 49px;
  right: 43px;
}

.hero-img--animation .item-5 {
  top: 2px;
  right: 176px;
}

@media (max-width: 991px) {
  .hero-img--animation .item-5 {
    display: none;
  }
}

.hero-img--animation .item-6 {
  left: 429px;
  top: -23px;
}

@media (max-width: 575px) {
  .hero-img .image {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .hero-img--right {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 1199px) {
  .hero-img--right {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 1366px) {
  .hero-img--right .image {
    max-width: 745px;
  }
}

@media (max-width: 1199px) {
  .hero-img--right .image {
    max-width: 545px;
  }
}

@media (max-width: 991px) {
  .hero-img--right .image {
    max-width: 100%;
  }
}

.hero-content {
  position: relative;
  z-index: 99;
}

@media (max-width: 1666px) {
  .hero-content {
    padding-right: 0;
  }
}

@media (max-width: 1199px) {
  .hero-content {
    padding-right: 0;
  }
}

.hero-content__title_small {
  color: #14133b;
  font-weight: 600;
  letter-spacing: 0.3em;
  font-size: calc(1.2625rem + 0.15vw);
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 1200px) {
  .hero-content__title_small {
    font-size: 1.375rem;
  }
}

@media (max-width: 1199px) {
  .hero-content__title_small {
    font-size: 1.125rem;
  }
}

@media (max-width: 575px) {
  .hero-content__title_small {
    font-size: 1rem;
  }
}

.hero-content__title_big {
  font-size: calc(1.5rem + 3vw);
  font-weight: 600;
  line-height: 1.18;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .hero-content__title_big {
    font-size: 3.75rem;
  }
}

@media (max-width: 1666px) {
  .hero-content__title_big {
    padding-right: 0;
  }
}

@media (max-width: 1366px) {
  .hero-content__title_big {
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .hero-content__title_big {
    padding: 0;
  }
}

.hero-content__paragraph {
  font-size: 18px;
  color: #14133b;
  margin-bottom: 45px;
  padding-right: 100px;
}

@media (max-width: 1199px) {
  .hero-content__paragraph {
    font-size: 16px;
    padding-right: 0px;
  }
}

.hero-agency--bg {
  min-height: 939px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 991px) {
  .hero-agency--bg {
    min-height: 620px;
  }
}

@media (max-width: 767px) {
  .hero-agency--bg {
    min-height: 520px;
  }
}

.hero-content--agency span {
  text-transform: capitalize;
  font-weight: 400;
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .hero-content--agency span {
    font-size: 1.5rem;
  }
}

.hero-content--agency h1, .hero-content--agency .h1 {
  margin-bottom: 20px;
  padding-right: 0;
  font-size: calc(1.625rem + 4.5vw);
}

@media (min-width: 1200px) {
  .hero-content--agency h1, .hero-content--agency .h1 {
    font-size: 5rem;
  }
}

.hero-content--agency .btn {
  margin: 0 8px;
}

@media (max-width: 575px) {
  .hero-content--agency .btn {
    margin: 5px 0;
  }
}

.hero-modern--bg {
  background: center center no-repeat;
  background-size: cover;
}

.hero-modern--bg::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.hero-content--modern .title {
  letter-spacing: 5px;
}

.hero-content--modern .heading {
  font-size: calc(1.75rem + 6vw);
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .hero-content--modern .heading {
    font-size: 6.25rem;
  }
}

.hero-content--modern .text {
  font-size: 20px;
  color: #ffffff;
}

.hero-content--modern .btn {
  margin-right: 17px;
}

.section-heading__title_small {
  color: #0554f2;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  padding-left: 15px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  display: inline-block;
  margin-bottom: 8px;
}

@media (max-width: 575px) {
  .section-heading__title_small {
    padding-left: 0;
  }
}

.section-heading__title_small::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  height: 15px;
  width: 5px;
  background: #0554f2;
  left: 0;
}

@media (max-width: 575px) {
  .section-heading__title_small::before {
    display: none;
  }
}

.section-heading__title_big {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .section-heading__title_big {
    font-size: 2.5rem;
  }
}

@media (max-width: 1199px) {
  .section-heading__title_big br {
    display: none;
  }
}

.section-heading__title_medium a svg {
  width: 20px;
  color: #0554f2;
}

.section-heading--style2 span {
  padding-left: 0;
  color: #c7c9cc;
  font-weight: 500;
  margin-bottom: 5px;
}

.section-heading--style2 span::before {
  display: none;
}

.section-heading--style3 span {
  color: #bdbdbd;
}

.section-heading--style3 span::before {
  background: #bdbdbd;
}

.section-heading--startup .section-heading__title_small {
  padding-left: 0;
  font-size: 15px;
  letter-spacing: 0.06em;
  padding: 4px 13px;
  background: #e6eeff;
  border-radius: 3px;
  margin-bottom: 14px;
}

.section-heading--startup .section-heading__title_small::before {
  display: none;
}

.section-heading--startup .section-heading__title_small.white-bg {
  background-color: #ffffff;
}

.section-software {
  padding: 0 78px;
}

@media (max-width: 1199px) {
  .section-software {
    padding: 0 0;
  }
}

.section-software__title_small {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .section-software__title_small {
    font-size: 15px;
  }
}

.section-software__title_big {
  font-weight: 600;
  margin-bottom: 18px;
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .section-software__title_big {
    font-size: 2.5rem;
  }
}

@media (max-width: 1199px) {
  .section-software__title_big > br {
    display: none;
  }
}

.section-software__title_big span {
  color: #0554f2;
}

@media (max-width: 767px) {
  .section-software__title_big br {
    display: none;
  }
}

.section-software__paragraph {
  font-size: 16px;
  color: #5b6674;
}

.section-software__light .title--small {
  color: #dbdbe3;
}

.section-software__light .title--big {
  color: #ffffff;
}

.section-software__light .paragraph {
  color: #dbdbe3;
}

.section-agency__line {
  height: 4px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
}

.section-agency__line_one {
  width: 40px;
}

.section-agency__line_two {
  width: 20px;
}

.section-agency__line_three {
  width: 10px;
}

.section-agency .color-secondary {
  background: #7e7d90;
}

.section-agency__title_big {
  margin-bottom: 15px;
}

.about--padding {
  padding: 130px 0 100px;
}

@media (max-width: 575px) {
  .about--padding {
    padding: 100px 0 70px 0;
  }
}

.about-agency--padding {
  padding-top: 130px;
  padding-bottom: 67px;
}

.about--inner {
  padding: 130px 0 100px 0;
}

@media (max-width: 991px) {
  .about--inner {
    padding: 130px 0 67px 0;
  }
}

.services--padding {
  padding: 122px 0 80px;
}

@media (max-width: 575px) {
  .services--padding {
    padding: 97px 0 50px 0;
  }
}

.services-key--padding {
  padding: 122px 0 100px;
}

@media (max-width: 575px) {
  .services-key--padding {
    padding: 97px 0 70px 0;
  }
}

.services-key--about {
  padding: 122px 0 117px;
}

.support--padding {
  padding-bottom: 362px;
}

.case--minus {
  margin-top: -434px;
}

.case-active .slick-dots {
  padding-top: 60px;
  text-align: center;
  line-height: 0;
}

.case-active .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  line-height: 0;
}

.case-active .slick-dots li button {
  text-indent: -99999px;
  width: 20px;
  height: 5px;
  border-radius: 2.5px;
  border: 1px solid transparent;
  background: #d9d9d9;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-active .slick-dots .slick-active button {
  width: 25px;
  background: transparent;
  border: 1px solid #d9d9d9;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-bg--chose {
  background: #f8f8f8;
}

.review--bg {
  background: #edf6ff;
}

.review--bg::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 100%;
  background: #ffffff;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 1666px) {
  .review--bg::before {
    width: 100px;
  }
}

@media (max-width: 1366px) {
  .review--bg::before {
    width: 100px;
  }
}

@media (max-width: 1199px) {
  .review--bg::before {
    display: none;
  }
}

.review--padding {
  padding: 122px 0 100px;
}

@media (max-width: 575px) {
  .review--padding {
    padding: 97px 0 70px 0;
  }
}

.review-wrapper {
  margin-right: 295px;
  padding-right: 170px;
  margin-left: -30px;
}

@media (max-width: 1666px) {
  .review-wrapper {
    margin-right: 60px;
    padding-right: 190px;
  }
}

@media (max-width: 1366px) {
  .review-wrapper {
    margin-right: 0;
    padding-right: 95px;
  }
}

@media (max-width: 1199px) {
  .review-wrapper {
    margin-right: 0;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .review-wrapper {
    margin-right: 0;
    padding-right: 0px;
    margin-left: 0px;
  }
}

.review--active .slick-arrow {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 1.5px solid #0554f2;
  color: #0554f2;
  border-radius: 100%;
  position: absolute;
  right: -130px;
  top: 122px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1366px) {
  .review--active .slick-arrow {
    right: -65px;
  }
}

.review--active .slick-arrow i {
  display: inline-flex;
  font-size: 15px;
}

.review--active .slick-arrow:hover {
  background: #0554f2;
  border: 1.5px solid #0554f2;
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.review--active .slick-arrow:hover i {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.review--active .slider-prev {
  top: 186px;
}

.review--software {
  padding-bottom: 203px;
}

.review-agency--bg {
  background-repeat: no-repeat;
  background-position: center bottom;
}

.price--padding {
  padding: 122px 0 100px;
}

@media (max-width: 575px) {
  .price--padding {
    padding: 97px 0 70px 0;
  }
}

.counter--bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.counter--bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 0;
}

.counter--bg .row,
.counter--bg .inner {
  position: relative;
  z-index: 2;
}

.counter--padding {
  padding: 140px 0 125px;
}

@media (max-width: 991px) {
  .counter--padding {
    padding: 75px 0 50px;
  }
}

.counter--software-bg {
  padding: 0 0 475px 0;
}

.counter--software-bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 0;
}

.counter--software-bg .row,
.counter--software-bg .inner {
  position: relative;
  z-index: 2;
}

.blog--padding {
  padding: 122px 0 100px;
}

@media (max-width: 575px) {
  .blog--padding {
    padding: 97px 0 70px 0;
  }
}

.blog--software-minus {
  margin-top: -350px;
}

.footer--padding {
  padding: 130px 0 100px 0;
}

@media (max-width: 575px) {
  .footer--padding {
    padding: 100px 0 70px 0;
  }
}

.footer--bottom-padding {
  padding: 16px 0;
}

.footer--bottom-border {
  border-top: 1px solid #282b43;
}

.footer--top {
  position: relative;
  z-index: 11;
}

.contact--bg {
  background: #f6f9ff;
}

.contact--padding {
  padding: 130px 0 100px;
}

@media (max-width: 575px) {
  .contact--padding {
    padding: 100px 0 70px 0;
  }
}

.tech--bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.9);
  z-index: 0;
}

.tech--bg .row,
.tech--bg .inner {
  position: relative;
  z-index: 2;
}

.solution--agency {
  padding-top: 122px;
  padding-bottom: 100px;
}

.project--agency {
  padding-top: 228px;
  padding-bottom: 100px;
  background: #f5f5f5;
  margin-top: -107px;
}

.feature--about {
  padding: 122px 0 100px;
}

.services-solutions--minus {
  margin-top: -45px;
}

.section-title--modern {
  padding: 0;
}

.section-title--modern .title--small {
  color: #8d8d8d;
  letter-spacing: 0.2em;
  font-size: 20px;
}

.section-title--modern .heading--big {
  font-weight: 600;
}

.footer--bg {
  background: #181a2e;
}

.footer--top-software--bg {
  background: #191b29;
}

.footer--top__software_top--border {
  border-bottom: 1px solid #323238;
}

.footer--bottom-software--bg {
  background: #1c1e2e;
  position: relative;
  z-index: 9999;
}

.footer__software_middle {
  position: relative;
  z-index: 9999;
}

.footer__software_address ul li {
  color: #ffffff;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  line-height: 1.6;
}

.footer__software_address ul li span {
  padding-left: 5px;
  color: #d7d7d7;
}

@media (max-width: 575px) {
  .footer--logo__software {
    text-align: center;
  }
}

.footer-widget__social.footer-social--modern > ul > li > a {
  background: #efefef;
}

.footer-widget__social ul {
  display: inline-flex;
}

.footer-widget__social_list {
  margin-right: 7px;
}

.footer-widget__social_list--link {
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 37px;
  background: #212338;
  border-radius: 7px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.footer-widget__social_list--link i {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.footer-widget__social_list .facebook i {
  color: #4064ac;
}

.footer-widget__social_list .facebook:hover {
  background: #4064ac;
}

.footer-widget__social_list .facebook:hover i {
  color: #ffffff;
}

.footer-widget__social_list .twitter i {
  color: #1c9cea;
}

.footer-widget__social_list .twitter:hover {
  background: #1c9cea;
}

.footer-widget__social_list .twitter:hover i {
  color: #ffffff;
}

.footer-widget__social_list .instagram i {
  color: #d1005c;
}

.footer-widget__social_list .instagram:hover {
  background: #d1005c;
}

.footer-widget__social_list .instagram:hover i {
  color: #ffffff;
}

.footer-widget__social_list .linkedin i {
  color: #016196;
}

.footer-widget__social_list .linkedin:hover {
  background: #016196;
}

.footer-widget__social_list .linkedin:hover i {
  color: #ffffff;
}

.footer-widget__social_list .pinterest i {
  color: #df0022;
}

.footer-widget__social_list .pinterest:hover {
  background: #df0022;
}

.footer-widget__social_list .pinterest:hover i {
  color: #ffffff;
}

.footer-widget__text {
  padding-right: 20px;
  margin-bottom: 32px;
}

.footer-widget__text_paragraph {
  color: #d7d7d7;
}

.footer-widget__title {
  margin-bottom: 17px;
}

.footer-widget__title_heading {
  color: #ffffff;
  font-size: 22px;
  position: relative;
  padding-left: 15px;
}

@media (max-width: 1199px) {
  .footer-widget__title_heading {
    font-size: 18px;
  }
}

.footer-widget__title_heading::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  height: 15px;
  width: 5px;
  background: #0554f2;
  left: 0;
}

.footer-widget__menu_list {
  margin-bottom: 17px;
}

.footer-widget__menu_list a {
  color: #d7d7d7;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  display: inline-block;
  position: relative;
}

.footer-widget__menu_list a::before {
  position: absolute;
  content: "";
  bottom: 2px;
  width: 0%;
  left: 100%;
  background: #fff;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.footer-widget__menu_list a:hover {
  color: #ffffff;
}

.footer-widget__menu_list a:hover::before {
  left: 0;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition: width 0.4s ease;
}

.footer-widget__menu_list:last-child {
  margin-bottom: 0;
}

.footer-widget__software_social {
  text-align: right;
}

@media (max-width: 575px) {
  .footer-widget__software_social {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .footer-widget__software_social ul li:last-child {
    margin-right: 0;
  }
}

.footer-widget--software .heading {
  padding-left: 0;
}

.footer-widget--software .heading::before {
  display: none;
}

.footer-widget--software__menu ul li a {
  position: relative;
  margin-left: 23px;
}

.footer-widget--software__menu ul li a:hover {
  color: #0554f2;
}

.footer-widget--software__menu ul li a::after {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  position: absolute;
  left: -23px;
  top: 50%;
  transform: translateY(-50%);
}

.footer-widget--software__menu ul li a::before {
  background: #0554f2;
}

.footer__copyright_text {
  color: #aaaaaa;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  text-align: center;
  margin-bottom: 0;
  position: relative;
  z-index: 11;
}

.footer__copyright_text a {
  color: #ffffff;
}

.footer__copyright_text a:hover {
  color: #0554f2;
}

.footer-shape {
  position: absolute;
}

@media (max-width: 575px) {
  .footer-shape {
    display: none;
  }
}

.footer-shape--left {
  width: 151px;
  height: 155px;
  left: 75px;
  top: 68px;
}

.footer-shape--right {
  width: 789px;
  height: 811px;
  top: -53%;
  right: -3%;
}

.footer-shape--bottom {
  bottom: 0;
  right: 0;
  z-index: 9;
}

.footer-modern--text > * {
  color: #5b6674;
}

.footer-title--modern {
  color: #14133b;
}

.footer-menu--modern > ul > li > a {
  color: #5b6674;
}

.footer-menu--modern > ul > li > a::before {
  background: #0554f2;
}

.footer-menu--modern > ul > li > a:hover {
  color: #0554f2;
}

.footer-copyright--modern {
  color: #5b6674;
}

.footer-copyright--modern > a {
  color: #5b6674;
}

.footer-bottom--modern {
  border-top-color: #e6e7ec;
}

.sticky-header {
  background: transparent;
}

.sticky-header.sticky-on.sticky {
  background-color: white;
}

.sticky-on.sticky.header--transparrent {
  border-bottom: 1px solid transparent;
  box-shadow: 0px 7px 0px 0px rgba(0, 0, 0, 0.03);
}

.sticky-on.sticky .main-menu--agency nav > ul > li {
  margin-right: 30px;
}

@media (max-width: 1199px) {
  .sticky-on.sticky .main-menu--agency nav > ul > li {
    margin-right: 15px;
  }
}

.sticky-on.sticky .main-menu--agency nav > ul > li > a {
  padding-left: 0;
  padding-right: 0;
  color: #14133b;
}

.sticky-on.sticky .main-menu--agency nav > ul > li > a:hover {
  color: #0554f2;
}

.sticky-on.sticky .main-menu--agency nav > ul > li > a::before {
  height: 1px;
}

.sticky-on.sticky .main-menu--agency nav > ul > li ul li a {
  padding: 8.5px 10px;
}

.sticky-on.sticky .main-menu--light nav ul li a {
  color: #14133b;
}

.sticky-on.sticky .main-menu--light nav ul li a:hover {
  color: #0554f2;
}

.sticky-on.sticky .header-right--agency ul li .search a {
  color: #14133b;
}

.sticky-on.sticky .header-right--agency ul li .search a:hover {
  color: #0554f2;
}

header.sticky-on.sticky .navbar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn--common {
  padding: 11px 12px 11px 25px;
  font-size: 16px;
  font-weight: 500;
  background: #eff4ff;
  color: #14133b;
  border-radius: 5px;
  outline: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.01em;
}

.btn--common:hover, .btn--common:focus, .btn--common:active {
  border: 1px solid transparent;
  outline: 0;
  box-shadow: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.btn rect.rt-button-line {
  height: 1.5px;
  y: 7;
}

.btn path.rt-button-cap {
  stroke-width: 1;
  stroke: #ffffff;
}

.btn--hover:hover, .btn--hover:focus {
  background: #0554f2;
  color: #ffffff;
}

.btn--primary {
  background: #0554f2;
  color: #ffffff;
}

.btn--primary:hover, .btn--primary:focus {
  color: #ffffff;
}

.btn--white {
  background: #ffffff;
  color: #0554f2;
}

.btn--white:hover {
  background: #ffffff;
  color: #0554f2;
}

.btn--white path.rt-button-cap {
  stroke: #0554f2;
}

.btn--link {
  background: transparent;
  padding: 0;
  font-size: 16px;
  color: #0554f2;
  font-weight: 400;
}

.btn--link path.rt-button-cap {
  stroke-width: 1;
  stroke: #0554f2;
}

.btn--border {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.btn--border:hover, .btn--border:focus {
  border: 1px solid #0554f2;
}

.btn--default {
  padding: 11px 35px;
}

.btn--default:hover, .btn--default:focus {
  background: #14133b;
}

.btn--tertiary {
  background: #e8effe;
  color: #14133b;
}

.btn--tertiary:hover, .btn--tertiary:focus {
  color: #ffffff;
}

.btn--secondary {
  background: #14133b;
  color: #ffffff;
}

.btn--common svg,
.btn--common svg {
  fill: currentColor;
  vertical-align: middle;
  margin-left: 15px;
  width: 26px;
  z-index: 1;
}

.btn--common .rt-button-line {
  stroke: currentColor;
  stroke-width: 0.5;
}

.btn--common .rt-button-cap,
.btn--common .rt-button-line {
  -webkit-transform: translateX(-13px);
  -ms-transform: translateX(-13px);
  transform: translateX(-13px);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  transition: 0.5s;
}

.btn--common:hover .rt-button-line {
  -webkit-animation: qode-button-line 0.4s ease forwards;
  animation: qode-button-line 0.4s ease forwards;
}

.btn--common:hover .rt-button-cap {
  -webkit-animation: qode-button-cap 0.4s ease forwards;
  animation: qode-button-cap 0.4s ease forwards;
}

.rt-button-animation-out .rt-button-cap-fake {
  -webkit-animation: qode-button-cap-fake-out 0.35s linear;
  animation: qode-button-cap-fake-out 0.35s linear;
}

.rt-button-animation-out .rt-button-cap {
  -webkit-animation: qode-button-cap-out 0.35s ease-in-out;
  animation: qode-button-cap-out 0.35s ease-in-out;
}

.rt-button-line {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
  stroke-width: 0.1;
  stroke: currentColor;
}

.rt-button-cap,
.rt-button-line {
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
  -o-transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
  transition: 0.33s cubic-bezier(0.14, -0.07, 0.23, 0.9);
}

@-webkit-keyframes qode-button-cap-out {
  0%,
  50% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
  }
  100%,
  51% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
}

@keyframes qode-button-cap-out {
  0%,
  50% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
  }
  100%,
  51% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
}

@-webkit-keyframes qode-button-cap-fake-out {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  49%,
  51% {
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

@keyframes qode-button-cap-fake-out {
  0% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  49%,
  51% {
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

@-webkit-keyframes qode-button-cap {
  0% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  54% {
    -webkit-transform: translateX(-1px) scaleX(1);
    transform: translateX(-1px) scaleX(1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  55% {
    -webkit-transform: translateX(-20px) scaleX(-1);
    transform: translateX(-20px) scaleX(-1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

@keyframes qode-button-cap {
  0% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
  54% {
    -webkit-transform: translateX(-1px) scaleX(1);
    transform: translateX(-1px) scaleX(1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  55% {
    -webkit-transform: translateX(-20px) scaleX(-1);
    transform: translateX(-20px) scaleX(-1);
    -webkit-clip-path: inset(50% 0 50% 0);
    clip-path: inset(50% 0 50% 0);
  }
  100% {
    -webkit-transform: translateX(-35px) scaleX(-1);
    transform: translateX(-35px) scaleX(-1);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

@-webkit-keyframes qode-button-line {
  0%,
  100% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  10% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  46%,
  55% {
    -webkit-transform: translateX(0) scaleX(0.5);
    transform: translateX(0) scaleX(0.5);
  }
  99% {
    -webkit-transform: translateX(-13px) scaleX(0.6);
    transform: translateX(-13px) scaleX(0.6);
  }
}

@keyframes qode-button-line {
  0%,
  100% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  10% {
    -webkit-transform: translateX(-13px) scaleX(1);
    transform: translateX(-13px) scaleX(1);
  }
  46%,
  55% {
    -webkit-transform: translateX(0) scaleX(0.5);
    transform: translateX(0) scaleX(0.5);
  }
  99% {
    -webkit-transform: translateX(-13px) scaleX(0.6);
    transform: translateX(-13px) scaleX(0.6);
  }
}

.sidebar-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 92.5px;
  width: 90px;
  overflow: hidden;
  background: #0554f2;
}

.sidebar-button button.offcanvas-menu-btn {
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border-radius: 4px;
  height: 91px;
  width: 90px;
  justify-content: center;
}

.sidebar-button button.offcanvas-menu-btn:focus {
  outline: none;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon {
  position: relative;
  display: block;
  width: 53px;
  height: 21px;
  overflow: hidden;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon::before, .sidebar-button button.offcanvas-menu-btn .menu-btn-icon::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon::after {
  bottom: 0;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon .line {
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon .line1 {
  top: 0;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon .line2 {
  top: 20px;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon .line3 {
  top: 10px;
}

.sidebar-button button.offcanvas-menu-btn .menu-btn-icon .line3::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon .line {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon .line1 {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon .line2 {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon .line3 {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
  transform: translateX(100%);
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon .line3::before {
  transform: translateX(-100%);
  -webkit-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon::before, .sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon::after {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon::before {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.sidebar-button button.offcanvas-menu-btn.menu-status-open:hover .menu-btn-icon::after {
  -webkit-transition-delay: 0.45s;
  -o-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.sidebar-button--vertical {
  position: static;
}

.sidebar-button--vertical button.offcanvas-menu-btn .menu-btn-icon {
  background-color: transparent;
  height: 76px;
  padding: 0;
}

.sidebar-button--vertical button.offcanvas-menu-btn:hover .menu-btn-icon {
  background: transparent;
}

@keyframes open_first_bar {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 65%;
    transform: translateY(-50%);
  }
}

@keyframes close_first_bar {
  0% {
    top: 65%;
    transform: translateY(-50%);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}

@keyframes open_second_bar {
  0%,
  50% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }
  51%,
  to {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes close_second_bar {
  0%,
  50% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
  51%,
  to {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes open_third_bar {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 35%;
    transform: translateY(-50%);
  }
}

@keyframes close_third_bar {
  0% {
    top: 35%;
    transform: translateY(-50%);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.offcanvas-menu-wrap {
  width: 510px;
  height: 100vh;
  position: fixed;
  transform: translateX(100%);
  top: 0;
  left: auto;
  right: 0;
  background-color: #14133b;
  z-index: 999999999;
  transition: transform 0.5s ease;
  transition-timing-function: linear;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.offcanvas-menu-wrap::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.offcanvas-menu-wrap[data-position="right"] {
  left: auto;
  right: 0;
  transform: translateX(-100%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.offcanvas-menu-wrap .close-btn {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  background: #0554f2;
  align-items: center;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.offcanvas-menu-wrap .close-btn:hover {
  color: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.offcanvas-menu-wrap .offcanvas-content {
  padding: 100px 56px 88px 80px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@media (max-width: 767px) {
  .offcanvas-menu-wrap .offcanvas-content {
    padding: 50px;
  }
}

@media (max-width: 575px) {
  .offcanvas-menu-wrap .offcanvas-content {
    padding: 50px;
  }
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-logo a {
  display: block;
  margin-bottom: 80px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav {
  text-align: center;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item {
  margin-bottom: 20px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item a {
  font-size: 18px;
  font-weight: 500;
  color: #14133b;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item a:hover {
  color: #0554f2;
}

.offcanvas-menu-wrap .offcanvas-content .sidebar-header .heading {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.offcanvas-menu-wrap .offcanvas-content .sidebar-header .text {
  margin-bottom: 24px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-address ul li {
  margin-bottom: 16px;
  padding-right: 50px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-address ul li .heading {
  margin-bottom: 5px;
  font-size: 22px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-address ul li .text {
  margin-bottom: 0;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer {
  text-align: center;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .item-title {
  font-size: 15px;
  color: #5b6674;
  margin-bottom: 14px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li {
  display: inline-block;
  margin-right: 6px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li:last-child {
  margin-right: 0;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li a {
  color: #575757;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li a:hover {
  color: #0554f2;
}

.offcanvas-menu-wrap .offcanvas-content .text {
  color: #dbdbdb;
}

.offcanvas-menu-wrap .offcanvas-content .rt-anima {
  transform: translateY(25px) translateX(15px);
  transition-delay: 0s;
  opacity: 0;
  visibility: hidden;
  transition: 0.7s;
}

.offcanvas-menu-wrap .offcanvas-content--nav {
  padding: 50px;
  height: 100vh;
  overflow-y: scroll;
}

.wrapper.open > .offcanvas-mask {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
}

.wrapper.open .offcanvas-content .rt-anima {
  transform: translateY(0px) translateX(0px);
  opacity: 1;
  visibility: visible;
  transition: 0.7s;
}

.wrapper.open .offcanvas-content .rt-anima--one {
  transition-delay: 0.4s;
}

.wrapper.open .offcanvas-content .rt-anima--two {
  transition-delay: 0.6s;
}

.wrapper.open .offcanvas-content .rt-anima--three {
  transition-delay: 0.8s;
}

.wrapper.open .offcanvas-content .rt-anima--four {
  transition-delay: 1s;
}

.wrapper.open .offcanvas-content .rt-anima--five {
  transition-delay: 1.2s;
}

.wrapper.open .offcanvas-content .rt-anima--six {
  transition-delay: 1.4s;
}

.wrapper.open .offcanvas-full-nav {
  position: static;
  background: transparent;
  left: 0;
  display: block;
  text-align: center;
  box-shadow: none;
}

.wrapper.open .offcanvas-full-nav .offscreen-navigation ul li a {
  color: #757493;
  font-size: 26px;
  font-weight: 500;
  padding: 6px 20px;
  display: inline-block;
}

.wrapper.open .offcanvas-full-nav .offscreen-navigation ul li a:hover {
  color: #ffffff;
}

.wrapper.open .offcanvas-full-nav .offscreen-navigation ul li .sub-menu li a {
  font-size: 18px;
  color: #a1a0c9;
}

.wrapper.open .offcanvas-full-nav .offscreen-navigation ul li .sub-menu li a:hover {
  color: #ffffff;
}

.wrapper.open .offcanvas-full-nav .offscreen-navigation ul .menu-item-has-children > a::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
  font-size: 15px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}

.offcanvas-full {
  width: 100%;
  transition: transform 0.91s ease;
}

.offcanvas-full .close-btn {
  position: static;
  background: transparent;
  padding: 0;
  justify-content: flex-end;
}

.offcanvas-full .close-btn:hover {
  color: #0554f2;
}

.offcanvas-full .offcanvas-topbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  padding: 61px 120px;
}

@media (max-width: 767px) {
  .offcanvas-full .offcanvas-topbar {
    padding: 15px 15px;
  }
}

@media (max-width: 575px) {
  .offcanvas-full .offcanvas-topbar {
    padding: 15px 15px;
  }
}

.about-content__title {
  color: #6e6e73;
  padding-bottom: 44px;
  border-bottom: 1px solid #0554f2;
  margin-bottom: 42px;
}

.about-content__text {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 0;
}

.about-content__experience {
  display: inline-flex;
  background: #0554f2;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  padding: 5px 30px;
  position: absolute;
  left: 40px;
  bottom: 40px;
  animation: dzMove5 8s linear infinite;
}

@media (max-width: 575px) {
  .about-content__experience {
    padding: 5px 15px;
    left: 5px;
    bottom: 5px;
  }
}

.about-content__experience_years {
  font-size: calc(1.5625rem + 3.75vw);
  position: relative;
  padding-right: 25px;
  margin-right: 25px;
  line-height: 1.652;
  text-shadow: 0px 7px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .about-content__experience_years {
    font-size: 4.375rem;
  }
}

.about-content__experience_years::after {
  content: "";
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #ffffff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.2);
  height: 40px;
  width: 2px;
}

.about-content__experience_title {
  letter-spacing: 0.1em;
  font-size: 20px;
  line-height: 1.5;
  text-shadow: 0px 7px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
}

@media (max-width: 575px) {
  .about-content__experience_title {
    font-size: 16px;
  }
}

.about-content__image {
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
}

.about-software__img {
  margin-left: -216px;
}

@media (max-width: 1199px) {
  .about-software__img {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .about-software__img {
    margin-left: 0;
  }
}

.about-software__img_anim {
  position: absolute;
  right: 61px;
  top: 12px;
}

@media (max-width: 1199px) {
  .about-software__img_anim {
    right: 34px;
    top: -60px;
  }
}

.about-software__content .title--small {
  font-size: 22px;
  color: #14133b;
  font-family: "Roboto", sans-serif;
  margin-bottom: 24px;
  display: block;
}

.about-software__content .paragraph {
  margin-bottom: 50px;
}

.video {
  display: inline-flex;
  align-items: center;
}

.video__title {
  font-size: 20px;
  font-weight: 500;
  padding-left: 25px;
  text-transform: uppercase;
  line-height: 1.4;
}

.video__title span {
  display: block;
}

.video .video-link {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  background: #0554f2;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  padding-left: 5px;
  animation: pulse 2s infinite;
}

/* pulse btn */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(5, 84, 242, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(5, 84, 242, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(5, 84, 242, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(5, 84, 242, 0.4);
    box-shadow: 0 0 0 0 rgba(5, 84, 242, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(5, 84, 242, 0);
    box-shadow: 0 0 0 45px rgba(5, 84, 242, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(5, 84, 242, 0);
    box-shadow: 0 0 0 0 rgba(5, 84, 242, 0);
  }
}

.about-image__agency_big {
  border-radius: 10px;
}

.about-image__agency_small {
  position: absolute;
  padding: 10px;
  background: #fff;
  right: -67px;
  bottom: -95px;
}

@media (max-width: 1199px) {
  .about-image__agency_small {
    right: 0;
    bottom: 0;
    display: none;
  }
}

@media (max-width: 991px) {
  .about-image__agency_small {
    display: none;
  }
}

.about-image .video {
  position: absolute;
  left: 124px;
  bottom: 190px;
}

@media (max-width: 767px) {
  .about-image .video {
    display: inline-block;
    left: 0;
    bottom: inherit;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}

.about-image .video .video-link {
  height: 70px;
  width: 70px;
  line-height: 70px;
}

.about-agency__content .title--small {
  font-size: 18px;
  color: #808086;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  margin-bottom: 40px;
}

.about-agency__right {
  margin-left: -31px;
}

@media (max-width: 991px) {
  .about-agency__right {
    margin-left: 0;
  }
}

.about-agency__right .list ul li {
  display: block;
  position: relative;
  padding-left: 40px;
  font-size: 22px;
  color: #14133b;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 10px;
}

.about-agency__right .list ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  line-height: 1;
}

.about-agency__right .list ul li i::before {
  font-size: 20px;
  vertical-align: middle;
  color: #0554f2;
}

.about-agency__right .list ul .paragraph {
  padding-right: 99px;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 16px;
  color: #5b6674;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 1.75;
}

@media (max-width: 767px) {
  .about-agency__right .list ul .paragraph {
    padding-right: 0;
  }
}

.about-image--feature {
  margin-left: 21px;
  display: inline-block;
}

@media (max-width: 991px) {
  .about-image--feature {
    margin-left: 0;
  }
}

.about-image--feature .video {
  left: auto;
  right: 27px;
  bottom: 27px;
}

.services-about article .link:hover {
  color: #0554f2;
}

.services-about article .link path.rt-button-cap {
  stroke: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-about article .link path.rt-button-line {
  fill: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-about article .link path.rt-button-line:hover {
  fill: #0554f2;
}

.about-image--modern {
  padding: 45px 45px 45px 0;
}

.about-image--modern .shape {
  position: absolute;
  content: "";
  height: 100%;
  width: 335px;
  right: 0;
  top: 0;
  background: #0554f2;
}

.about-image--modern .inner {
  position: relative;
  z-index: 11;
  margin-left: -45px;
}

.about-image--modern .video {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  left: 0;
  justify-content: center;
}

.about-image--modern .video .video-link {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-startup .item--one {
  display: flex;
}

.about-startup .item--one .image2 {
  margin-left: 63px;
  margin-top: -38px;
}

@media (max-width: 767px) {
  .about-startup .item--one .image2 {
    display: none;
  }
}

.about-startup .item--two {
  text-align: right;
}

.about-startup .item--two .image3 {
  margin-top: -220px;
  margin-right: 112px;
}

@media (max-width: 767px) {
  .about-startup .item--two .image3 {
    margin-right: 0;
    margin-top: 0;
  }
}

.services__item {
  padding: 34px 35px 29px;
  border-left: 2px solid #dee9fe;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .services__item {
    padding: 25px 15px 20px;
  }
}

.services__item:hover {
  border-radius: 5px;
  border-left: 2px solid transparent;
}

.services__item:hover .services__item_icon {
  color: #ffffff;
}

.services__item:hover .services__item_title {
  color: #ffffff;
}

.services__item:hover .services__item_paragraph {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__item:hover .services__item_overlay {
  width: 100%;
  left: 0%;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__item_header {
  margin-bottom: 17px;
}

.services__item_title {
  margin-bottom: 13px;
  padding-left: 22px;
  position: relative;
  z-index: 2;
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .services__item_title {
    font-size: 1.5rem;
  }
}

@media (max-width: 575px) {
  .services__item_title {
    padding-left: 18px;
  }
}

.services__item_title:hover a {
  color: #ffffff;
}

.services__item_icon {
  position: relative;
  z-index: 2;
  display: inline-flex;
  color: #0554f2;
}

.services__item_icon::before {
  font-size: 47px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .services__item_icon::before {
    font-size: 29px;
  }
}

.services__item_paragraph {
  margin-bottom: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  position: relative;
  z-index: 2;
}

.services__item_overlay {
  display: block;
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0%;
  left: 0%;
  color: #ffffff;
  background-color: #0554f2;
  z-index: 1;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-key__navbar {
  text-align: center;
}

.services-key__navbar_nav {
  border-bottom: 2px solid #dbdbe3;
  display: inline-flex;
  justify-content: center;
}

.services-key__navbar_item {
  color: #14133b;
}

.services-key__navbar_link {
  font-size: 20px;
  color: #14133b;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  padding: 15px 30px;
  position: relative;
  margin-bottom: -2px !important;
}

.services-key__navbar_link::before {
  --scale: 0;
  position: absolute;
  content: "";
  height: 2px;
  background: #0554f2;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -1px;
  transform: scaleX(var(--scale));
  transform-origin: var(--x) 50%;
  transition: transform 0.4s cubic-bezier(0.51, 0.92, 0.24, 1);
}

.services-key__navbar_link:hover, .services-key__navbar_link:focus {
  border-color: transparent !important;
  border: 1px solid transparent !important;
}

.services-key__navbar_link i {
  font-size: 30px;
  line-height: 1;
  padding-right: 11px;
}

.services-key__navbar_link.nav-link.active {
  border: 1px solid transparent;
  border-color: transparent;
  background-color: transparent;
  color: #0554f2;
}

.services-key__navbar_link.nav-link.active::before {
  --scale: 1;
}

.services-key__content {
  background: #edf6ff;
  border: 2px solid transparent;
  padding: 50px 25px 43px;
  border-radius: 15px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .services-key__content {
    padding: 20px 15px 20px;
  }
}

.services-key__content:hover {
  border-radius: 15px !important;
  background: transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-key__content:hover .services__item_overlay {
  left: auto;
  right: 0;
}

.services-key__content:hover .btn--link {
  color: #ffffff;
}

.services-key__content_header {
  margin-bottom: 8px;
}

.services-key__content_title {
  padding-left: 0;
}

@media (max-width: 1199px) {
  .services-key__content_title {
    font-size: 20px;
  }
}

.services-key__content_icon {
  margin-bottom: 18px;
  color: #000000;
}

.services-key__content_icon::before {
  font-size: 60px;
}

.services-key__content_paragraph {
  margin-bottom: 14px;
}

.services-key__content_link {
  position: relative;
  z-index: 2;
  color: #0554f2;
  font-size: 16px;
}

.services-key__content_link:hover {
  padding-left: 10px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  color: #14133b;
}

.services-key__content:hover .services-key__content_link {
  color: #ffffff;
}

.services-key__content:hover .services-key__content_link:hover {
  color: #14133b;
}

.services-key__content:hover .services-key__content_overlay {
  border-radius: 15px;
}

.services-key__content:hover .services-key__content .btn--link {
  color: #ffffff;
}

.services-key__content:hover .btn--link path.rt-button-cap {
  stroke-width: 1;
  stroke: #ffffff;
}

.services-key__content_overlay {
  border-radius: 15px;
}

.services__tech {
  border: 2px solid #f2f2f2;
  padding: 67px 0 60px;
  border-radius: 10px;
}

.services__tech .services__item_overlay {
  width: 100%;
  height: 0%;
}

.services__tech:hover {
  border: 2px solid transparent;
}

.services__tech:hover .services__item_overlay {
  height: 100%;
  bottom: 0;
}

.services__tech_icon {
  margin-bottom: 12px;
}

.services__tech_icon::before {
  font-size: 84px;
}

.services__tech_title {
  padding-left: 0;
  font-size: 30px;
  letter-spacing: 0.1em;
}

.services__tech_overlay {
  border-radius: 10px;
}

.services-partners__wrapper {
  margin-left: -5px;
  margin-right: -5px;
}

.services-partners__item {
  float: left;
  width: 15.8%;
  margin: 0 5px 10px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .services-partners__item {
    width: 18.94%;
  }
}

@media (max-width: 991px) {
  .services-partners__item {
    width: 23.5%;
  }
}

@media (max-width: 575px) {
  .services-partners__item {
    width: 46.7%;
  }
}

.services-partners__item_link {
  display: inline-block;
  width: 100%;
  height: 160px;
  text-align: center;
  line-height: 160px;
  background: #f8faff;
  border-radius: 5px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-partners__item_link img {
  filter: grayscale(100%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-partners__item_link:hover img {
  filter: invert(100%) sepia(92%) saturate(4039%) hue-rotate(222deg) brightness(97%) contrast(97%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__awards {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);
  padding: 40px 27px 20px;
  text-align: center;
}

.services__awards_board {
  margin-bottom: 29px;
  background-color: #d7d7d7;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.14);
}

.services__awards_title {
  font-size: 20px;
}

.services-software {
  padding: 0;
  border-left: 0;
  text-align: center;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  padding: 49px 47px 42px;
}

@media (max-width: 1366px) {
  .services-software {
    padding: 49px 37px 42px;
  }
}

@media (max-width: 1199px) {
  .services-software {
    padding: 49px 13px 42px;
  }
}

.services-software .services__item_overlay {
  width: 100%;
  height: 0%;
  bottom: inherit;
}

.services-software:hover {
  border: 0;
}

.services-software:hover .services__item_overlay {
  height: 100%;
  top: inherit;
  bottom: 0;
}

.services-software:hover .services-software__header .icon {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-software:hover .services-software__header .heading a {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-software:hover .services-software__content .paragraph {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services-software:hover .services-software__content .link {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  padding-left: 0px;
}

.services-software:hover .services-software__content .link svg {
  visibility: visible;
  opacity: 1;
}

.services-software:hover .services-software__content .link svg path.rt-button-cap {
  stroke: #ffffff;
}

.services-software__header {
  position: relative;
  z-index: 2;
}

.services-software__header .icon {
  font-size: calc(1.7125rem + 5.55vw);
  line-height: 1;
  display: inline-flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  color: #0554f2;
}

@media (min-width: 1200px) {
  .services-software__header .icon {
    font-size: 5.875rem;
  }
}

.services-software__header .heading {
  font-size: calc(1.2875rem + 0.45vw);
  margin-bottom: 0;
  padding: 13px 0 13px;
}

@media (min-width: 1200px) {
  .services-software__header .heading {
    font-size: 1.625rem;
  }
}

.services-software__content {
  position: relative;
  z-index: 2;
}

.services-software__content .paragraph {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  margin-bottom: 28px;
}

.services-software__content .link {
  padding-left: 33px;
}

.services-software__content .link svg {
  visibility: hidden;
  opacity: 0;
  width: 23px;
  margin-left: 10px;
}

.tech-software {
  text-align: center;
  padding: 34px 15px 21px;
  border: 1px solid #59597b;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

@media (max-width: 1199px) {
  .tech-software {
    padding: 22px 6px 10px;
  }
}

.tech-software:hover {
  border: 1px solid #0554f2;
  transition: all 1s ease-in-out;
}

.tech-software .icon--tech {
  font-size: 59px;
  display: inline-flex;
  color: #ffffff;
  margin-bottom: 13px;
  position: relative;
  z-index: 2;
}

.tech-software__heading {
  font-size: 18px;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.services-agency__wrapper {
  border-radius: 10px;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

@media (max-width: 991px) {
  .services-agency__wrapper .row {
    display: flex;
    justify-content: center;
  }
}

.services-agency--minus {
  margin-top: -153px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .services-agency--minus {
    margin-top: -60px;
  }
}

.services-item {
  border-radius: 0;
  box-shadow: 0px 0px 0px 0px;
  position: relative;
  padding: 75px 50px;
}

@media (max-width: 1199px) {
  .services-item {
    padding: 50px 20px;
  }
}

@media (max-width: 991px) {
  .services-item {
    padding: 70px 20px;
  }
}

@media (max-width: 767px) {
  .services-item {
    padding: 20px 20px;
  }
}

.services-item:hover {
  border-radius: 0;
}

.services-item:hover .btn path.rt-button-cap {
  stroke: #0554f2;
}

.services-item .header .icon {
  font-size: 60px;
}

.services-item .btn {
  font-size: 18px;
  color: #14133b;
  padding: 0;
  background: transparent;
  font-weight: 500;
}

.services-item .btn path.rt-button-cap {
  stroke-width: 1.5;
  stroke: #14133b;
}

.services-item .btn:hover path.rt-button-cap {
  stroke: #0554f2;
}

.services-item:hover .header .icon {
  color: #14133b;
}

.services-item:hover .header .heading a {
  color: #0554f2;
}

.services-item:hover .content .paragraph {
  color: inherit;
}

.services-item:hover .btn {
  color: #0554f2;
}

.services-item--border::before, .services-item--border::after {
  content: "";
  height: 150px;
  width: 2px;
  background: #e6e6e6;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.services-item--border::before {
  left: 0;
}

@media (max-width: 767px) {
  .services-item--border::before {
    display: none;
  }
}

@media (max-width: 575px) {
  .services-item--border::before {
    display: none;
  }
}

.services-item--border::after {
  right: 0;
}

@media (max-width: 991px) {
  .services-item--border::after {
    display: none;
  }
}

.services-about {
  text-align: center;
  padding: 30px 16px 35px;
  background: #ffffff;
  overflow: inherit;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .services-about {
    padding: 30px 10px 35px;
  }
}

@media (max-width: 991px) {
  .services-about {
    padding: 30px 24px 35px;
  }
}

.services-about header .icon {
  display: inline-flex;
  margin-bottom: 20px;
}

.services-about header .icon::before {
  font-size: 70px;
  color: #0554f2;
}

.services-about header h4, .services-about header .h4 {
  font-weight: 600;
}

.services-about article .link {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededf1;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -20px;
  margin: 0 auto;
  z-index: 23;
}

.services-about article .link svg {
  margin-left: 10px;
}

.services-about:hover header .icon::before {
  color: #ffffff;
}

.services__details .image {
  position: relative;
  display: inline-block;
  width: 100%;
}

.services__details .image img {
  width: 100%;
  border-radius: 10px;
}

.services__details .image--big {
  margin-bottom: 42px;
}

.services__details .image .icon {
  font-size: 60px;
  background: #0554f2;
  height: 120px;
  width: 120px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #ffffff;
  position: absolute;
  bottom: 30px;
  left: 30px;
  border-radius: 10px;
}

@media (max-width: 575px) {
  .services__details .image .icon {
    font-size: 40px;
    height: 80px;
    width: 80px;
  }
}

.services__details .image .icon--circle {
  border-radius: 100%;
  top: 0;
  bottom: 0;
  right: -60px;
  left: auto;
  margin: auto 0;
}

@media (max-width: 767px) {
  .services__details .image .icon--circle {
    right: 0;
  }
}

.services__details .image .icon--center {
  border-radius: 50%;
  margin: auto;
  background-color: #14133b;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.services__details .image--special img {
  border-radius: 100%;
}

.services__details .content .heading {
  font-weight: 600;
}

.services__details .content .heading--big {
  margin-bottom: 20px;
  font-size: 40px;
}

.services__details .content .heading--small {
  font-size: calc(1.3rem + 0.6vw);
  margin-bottom: 12px;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .services__details .content .heading--small {
    font-size: 1.75rem;
  }
}

.services__details .content .paragraph {
  margin-bottom: 1.8rem;
}

.services__details .content .list ul li {
  display: block;
  position: relative;
  padding-left: 29px;
  font-size: 15px;
  color: #14133b;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 20px;
  float: left;
  width: 50%;
}

@media (max-width: 575px) {
  .services__details .content .list ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .services__details .content .list ul li {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .services__details .content .list ul li {
    width: 100%;
  }
}

.services__details .content .list ul li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  line-height: 1;
  left: 0;
  color: #0554f2;
}

.services__details .content .list ul li i::before {
  font-size: 18px;
  vertical-align: middle;
}

.services__details .content--right {
  padding: 0 20px 0 70px;
}

@media (max-width: 767px) {
  .services__details .content--right {
    padding: 0;
  }
}

.services__details .content--right .faq-accordion {
  min-height: 395px;
}

.services__details .content--right .faq-accordion .accordion-item {
  margin-bottom: 20px;
}

.services__details .content--right .faq-accordion .accordion-item .accordion-header .accordion-button {
  padding: 11px 23px 11px 30px;
}

.services__details .content--right .faq-accordion .accordion-item .accordion-body {
  margin-top: 13px;
  padding: 15px 30px;
}

.services__details .content--right .faq-accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.services__details .content--special .heading--small {
  letter-spacing: 0.1em;
  display: block;
  margin-bottom: 7px;
}

.services__sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 115px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__sidebar .menu {
  overflow: hidden;
  border-radius: 10px;
  padding: 10px 0;
  background: #14133b;
}

.services__sidebar .menu ul li a {
  display: block;
  padding: 16px 61px 16px 89px;
  color: #ababbe;
  font-size: 18px;
  position: relative;
  border-bottom: 1px solid #2e2d58;
}

@media (max-width: 1199px) {
  .services__sidebar .menu ul li a {
    padding: 16px 35px 16px 50px;
  }
}

.services__sidebar .menu ul li a::before, .services__sidebar .menu ul li a::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__sidebar .menu ul li a::before {
  width: 10px;
  height: 10px;
  border: 1px solid #ababbe;
  border-radius: 50%;
  left: 62px;
}

@media (max-width: 1199px) {
  .services__sidebar .menu ul li a::before {
    left: 25px;
  }
}

.services__sidebar .menu ul li a::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  right: 62px;
}

@media (max-width: 1199px) {
  .services__sidebar .menu ul li a::after {
    right: 25px;
  }
}

.services__sidebar .menu ul li a:hover {
  color: #ffffff;
}

.services__sidebar .menu ul li a:hover::before {
  border-color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.services__sidebar .menu ul li a:hover::after {
  right: 55px;
}

.services__sidebar .menu ul li:last-child a {
  border-bottom: 1px solid transparent;
}

.services__sidebar .brouchers {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
  padding: 40px 50px;
}

@media (max-width: 1366px) {
  .services__sidebar .brouchers {
    padding: 35px 40px;
  }
}

@media (max-width: 991px) {
  .services__sidebar .brouchers {
    padding: 25px 20px;
  }
}

@media (max-width: 767px) {
  .services__sidebar .brouchers {
    padding: 25px 12px;
  }
}

.services__sidebar .brouchers .heading {
  margin-bottom: 22px;
}

.services__sidebar .brouchers .item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.services__sidebar .brouchers .item:last-child {
  margin-bottom: 0;
}

.services__sidebar .brouchers .item .brouchers-icon {
  display: inline-flex;
  min-width: 55px;
}

.services__sidebar .brouchers .item .brouchers-icon i {
  display: inline-flex;
  font-size: 47px;
  color: #0554f2;
}

.services__sidebar .brouchers .item .brouchers-text {
  padding-left: 9px;
}

.services__sidebar .brouchers .item .brouchers-text .heading {
  margin-bottom: 3px;
  font-size: 18px;
  font-weight: 600;
}

.services__sidebar .brouchers .item .brouchers-text .link {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "Roboto", sans-serif;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  display: block;
}

.services__sidebar .brouchers .item .brouchers-text .link:hover {
  letter-spacing: 0.1em;
}

.services__sidebar .contact-address--bg {
  padding: 50px 40px;
}

@media (max-width: 575px) {
  .services__sidebar .contact-address--bg {
    padding: 50px 25px;
  }
}

.services__sidebar .person {
  position: relative;
  z-index: 1;
  text-align: center;
}

.services__sidebar .person .image {
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

.services__sidebar .person .phone,
.services__sidebar .person .email {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

.services__sidebar .person .social {
  position: static;
  padding-top: 10px;
}

.services__sidebar .person .social ul li {
  opacity: 1;
  visibility: visible;
  display: inline-block;
  transform: translateX(0px);
}

.services__sidebar .person .social ul li a {
  background: #eff4ff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  margin: 0 4px;
}

.services__sidebar .person .social ul li a:hover {
  background: #14133b;
  color: #ffffff !important;
}

.services__sidebar .person .social ul li:hover a {
  color: #0554f2;
}

.services-item--modern {
  text-align: left;
  padding: 54px 35px 50px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
}

.services-item--modern .header .icon {
  font-size: 70px;
}

.services-item--modern .header .heading {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .services-item--modern .header .heading {
    font-size: 1.875rem;
  }
}

.services-item--modern:hover {
  border-radius: 10px;
  background-color: #0554f2;
  box-shadow: none;
}

.services-item--modern:hover .header .icon {
  color: #ffffff;
}

.services-item--modern:hover .header .heading > a {
  color: #ffffff;
}

.services-item--modern:hover .content .paragraph {
  color: #ffffff;
}

.services-modern--bg {
  position: relative;
  margin-top: -136px;
}

.services-modern--bg::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5f8ff;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.services__item--modern {
  padding: 55px 43px 50px;
  text-align: left;
}

.services__item--modern .icon-box {
  position: relative;
  display: inline-flex;
  width: 100px;
  height: 92px;
  justify-content: center;
  align-items: center;
  margin-bottom: 9px;
}

.services__item--modern .icon-box::before {
  position: absolute;
  content: "";
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f2f6fe;
  border-radius: 46% 54% 50% 50% / 32% 33% 67% 68%;
  width: 100%;
  height: 100%;
  transform: rotate(-7deg);
}

.services__item--modern .icon {
  font-size: 42px;
  position: relative;
  z-index: 1;
}

.services__item--modern .content .link {
  padding-left: 0;
}

.services__item--modern:hover .icon-box::before {
  background: #ffffff;
}

.services__item--modern:hover .icon-box .icon {
  color: #0554f2;
}

.services__modern--wrapper {
  margin-right: 94px;
}

@media (max-width: 1199px) {
  .services__modern--wrapper {
    margin-right: 0;
  }
}

.services__modern--row1 {
  position: relative;
}

.services__modern--row2 {
  padding-top: 147px;
  position: relative;
}

.services__modern--right {
  padding-right: 58px;
}

.services__modern--shape {
  position: absolute;
}

@media (max-width: 1199px) {
  .services__modern--shape {
    display: none;
  }
}

.services__modern--shape1 {
  right: -34px;
  top: 100px;
  left: auto;
  text-align: right;
}

.services__modern--shape2 {
  left: -55px;
  top: 50%;
  transform: translateY(-50%);
}

.service-modern--block .image,
.service-modern--block .image > img {
  overflow: hidden;
  border-radius: 10px;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.service-modern--block .services-item--modern {
  text-align: center;
  padding: 37px 23px 32px;
  margin-top: -62px;
  margin-left: 15px;
  margin-right: 15px;
}

.service-modern--block .services-item--modern .header .icon {
  font-size: calc(1.41875rem + 2.025vw);
}

@media (min-width: 1200px) {
  .service-modern--block .services-item--modern .header .icon {
    font-size: 2.9375rem;
  }
}

.service-modern--block .services-item--modern .header .heading {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .service-modern--block .services-item--modern .header .heading {
    font-size: 1.5rem;
  }
}

.service-modern--block:hover .image > img {
  transform: scale3d(1.3, 1.3, 1.3);
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.service-modern--block:hover .services-item--modern {
  border-radius: 10px;
  background-color: #0554f2;
  box-shadow: none;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.service-modern--block:hover .services-item--modern .header .icon {
  color: #ffffff;
}

.service-modern--block:hover .services-item--modern .header .heading > a {
  color: #ffffff;
}

.service-modern--block:hover .services-item--modern .content .paragraph {
  color: #ffffff;
}

.services-startup {
  padding: 40px 29px 35px;
}

@media (max-width: 1199px) {
  .services-startup {
    padding: 40px 9px 35px;
  }
}

@media (max-width: 767px) {
  .services-startup {
    padding: 40px 29px 35px;
  }
}

.services-startup .icon {
  font-size: calc(1.5625rem + 3.75vw);
}

@media (min-width: 1200px) {
  .services-startup .icon {
    font-size: 4.375rem;
  }
}

.services-startup .heading {
  font-size: calc(1.275rem + 0.3vw);
  padding: 6px 0 10px;
}

@media (min-width: 1200px) {
  .services-startup .heading {
    font-size: 1.5rem;
  }
}

.services-startup .paragraph {
  margin-bottom: 10px;
}

.support-box {
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.support__software_content .title em {
  font-weight: 300;
}

.support__software_button {
  text-align: right;
}

@media (max-width: 575px) {
  .support__software_button {
    text-align: center;
  }
}

.support__software_button .btn {
  margin-left: 15px;
}

@media (max-width: 575px) {
  .support__software_button .btn {
    margin: 7.5px 0;
  }
}

.solution-tab ul li.nav-item .nav-link {
  padding: 0;
  background: transparent;
  border-radius: 0;
}

.solution-tab__wrapper {
  background: #f6f9ff;
  padding: 0 5px;
}

.solution-tab__image {
  margin-right: -264px;
  display: inline-block;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .solution-tab__image {
    margin-right: 0;
  }
}

@media (max-width: 1366px) {
  .solution-tab__image {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .solution-tab__image {
    margin-right: 0;
  }
}

.solution-tab__image .image--replace {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
  top: 30px;
}

.solution-tab .featured-agency {
  background: #ebf1fb;
  padding: 28px 33px 28px 60px;
  border-radius: 0 5px 5px 0;
  margin: 5px 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .solution-tab .featured-agency {
    padding: 28px 15px 28px 15px;
  }
}

.solution-tab .featured-agency:hover {
  background: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-radius: 0 0px 0px 0;
}

.solution-tab .featured-agency:hover .header .icon {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.solution-tab .featured-agency:hover .article .heading {
  color: #ffffff;
}

.solution-tab .featured-agency:hover .article .paragraph {
  color: #ffffff;
}

.solution-tab .featured-agency .header {
  min-width: 70px;
  padding-top: 8px;
}

@media (max-width: 575px) {
  .solution-tab .featured-agency .header {
    min-width: 40px;
  }
}

.solution-tab .featured-agency .header .icon {
  font-size: calc(1.5rem + 3vw);
  color: #0554f2;
  display: inline-flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .solution-tab .featured-agency .header .icon {
    font-size: 3.75rem;
  }
}

.solution-tab .featured-agency .article {
  padding-left: 15px;
  text-align: left;
}

.solution-tab .featured-agency .article .heading {
  font-size: 24px;
  color: #14133b;
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .solution-tab .featured-agency .article .heading {
    font-size: 20px;
  }
}

.solution-tab .featured-agency .article .heading:hover a, .solution-tab .featured-agency .article .heading:focus a, .solution-tab .featured-agency .article .heading:active a {
  color: #ffffff !important;
}

.solution-tab .featured-agency .article .heading a:hover, .solution-tab .featured-agency .article .heading a:active {
  color: #ffffff !important;
}

.solution-tab .featured-agency .article .paragraph {
  color: #5b6674;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  margin-bottom: 0;
}

.solutions-button > .btn {
  margin: 0px 7.5px;
  font-weight: 500;
  margin-bottom: 20px;
}

.review-block {
  margin: 0 15px;
}

@media (max-width: 767px) {
  .review-block {
    margin: 0 4.5px;
  }
}

.review-block__content {
  background: #ffffff;
  padding: 71px 47px;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 575px) {
  .review-block__content {
    padding: 25px 25px;
  }
}

.review-block__content p {
  margin-bottom: 0;
}

.review-block__content::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 15px 0 15px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -16px;
  left: 70px;
}

.review-block__quote_icon {
  position: absolute;
  right: 40px;
  top: 26px;
}

.review-block__title {
  margin-bottom: 14px;
}

.review-block__auth {
  padding: 30px 0 0 47px;
  display: flex;
  align-items: center;
}

.review-block__auth_name {
  margin-bottom: 1px;
  font-size: 22px;
}

.review-block__auth_title {
  color: #575757;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.review-block__auth_info {
  padding-left: 15px;
}

.review-block__auth_image a {
  display: inline-block;
  width: 60px;
}

.review-software {
  text-align: center;
  padding: 54px 35px 47px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

@media (max-width: 1199px) {
  .review-software {
    padding: 40px 15px 37px;
  }
}

.review-software__image {
  position: relative;
  display: inline-block;
  margin-bottom: 35px;
}

.review-software__image .image {
  border-radius: 50%;
  border: 3px solid #f0f2ff;
}

.review-software__image .icon {
  position: absolute;
  right: -13px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  bottom: 4px;
}

.review-software__image .icon--one {
  background: #25aab8;
}

.review-software__image .icon--two {
  background: #6f273b;
}

.review-software__image .icon--three {
  background: #c2bb5d;
}

.review-software__content .paragraph {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 30px;
  display: block;
  position: relative;
  margin-bottom: 25px;
  color: #6e6e73;
}

.review-software__content .paragraph::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background: #0554f2;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.review-software__content .heading {
  margin-bottom: 2px;
}

.review-software__content .title {
  font-size: 18px;
  color: #575757;
}

.review-agency .content {
  text-align: center;
  padding: 45px 45px;
}

@media (max-width: 575px) {
  .review-agency .content {
    padding: 30px 15px;
  }
}

.review-agency .content::before {
  display: none;
}

.review-agency .content .quote {
  position: static;
  line-height: 1;
  display: inline-flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  background: #0554f2;
  color: #ffffff;
  font-size: 35px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.review-agency .content .paragraph {
  font-size: 18px;
  font-weight: 500;
  color: #14133b;
  font-style: italic;
}

@media (max-width: 575px) {
  .review-agency .content .paragraph {
    font-size: 16px;
  }
}

.review-agency .content .auth {
  padding-left: 0;
  justify-content: center;
}

.review-agency .content .info {
  text-align: left;
}

.review-agency .content .info h3, .review-agency .content .info .h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.review-agency .content .info span {
  font-size: 14px;
}

.review-agency--active .slick-dots {
  text-align: center;
  line-height: 0;
  padding: 30px 0;
}

.review-agency--active .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  line-height: 0;
}

.review-agency--active .slick-dots li button {
  text-indent: -99999px;
  width: 20px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid #d9d9d9;
  background: transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.review-agency--active .slick-dots .slick-active button {
  width: 30px;
  background: #ffffff;
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.testi-active__col {
  margin: 0 15px;
}

.review-modern {
  border-color: #3276fe;
}

.review-modern .paragraph::after {
  background-color: #3276fe;
}

.price-item {
  padding: 56px 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  position: relative;
}

@media (max-width: 1366px) {
  .price-item {
    padding: 56px 15px;
  }
}

.price-item::before, .price-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 14px;
  background: #0554f2;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: -1;
}

.price-item::before {
  border-radius: 10px 10px 0 0;
  top: 0;
}

.price-item::after {
  border-radius: 0px 0px 10px 10px;
  bottom: 0;
}

.price-item:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: #0554f2 !important;
  border-radius: 0;
}

.price-item:hover::before, .price-item:hover::after {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover::before {
  top: -14px;
}

.price-item:hover::after {
  bottom: -14px;
}

.price-item:hover .price-item__header_icon {
  background: #044ee4 !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover .price-item__title {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover .price-item__price {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover .price-item__content_item span {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover .price-item__content_item i {
  color: #ffffff;
}

.price-item:hover .price-item__button {
  background: #ffffff;
  color: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.price-item:hover .price-item__button:hover {
  background: transparent;
  color: #fff;
  border-color: #ffff;
}

.price-item__wrapper:nth-child(odd) .price-item {
  background: #f8faff;
}

.price-item__wrapper:nth-child(odd) .price-item:hover {
  border-radius: 0;
}

.price-item__wrapper:nth-child(even) .price-item {
  background: #edf6ff;
}

.price-item__wrapper:nth-child(even) .price-item:hover {
  border-radius: 0;
}

.price-item__wrapper:nth-child(even) .price-item__header_icon {
  background: #ffffff;
}

.price-item__wrapper:first-child .price-item {
  border-radius: 10px 0 0px 10px;
}

@media (max-width: 991px) {
  .price-item__wrapper:first-child .price-item {
    border-radius: 0;
  }
}

.price-item__wrapper:last-child .price-item {
  border-radius: 0px 10px 10px 0px;
}

@media (max-width: 991px) {
  .price-item__wrapper:last-child .price-item {
    border-radius: 0;
  }
}

.price-item__header {
  margin-bottom: 16px;
}

.price-item__header_icon {
  display: inline-flex;
  height: 120px;
  width: 120px;
  justify-content: center;
  align-items: center;
  background: #edf6ff;
  border-radius: 50%;
  padding: 15px 10px;
  margin-bottom: 30px;
}

.price-item__icon {
  line-height: 0;
}

.price-item__price {
  font-size: 36px;
  font-weight: 500;
  color: #0554f2;
}

.price-item__price span {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.price-item__content {
  margin-bottom: 35px;
}

.price-item__content_item {
  margin-bottom: 12px;
}

.price-item__content_item span {
  font-size: 16px;
  color: #5b6674;
  font-family: "Roboto", sans-serif;
  padding-left: 10px;
}

.price-item__content_item i {
  color: #b7b7b7;
  font-size: 12px;
}

.price-item__button {
  font-size: 18px;
  color: #0554f2;
  border: 1px solid #0554f2;
  line-height: 1;
  border-radius: 4px;
  padding: 15px 30px;
  background: transparent;
}

.price-item--agency {
  padding: 56px 64px;
  border: 3px solid #edf6ff;
  border-radius: 10px;
}

@media (max-width: 1366px) {
  .price-item--agency {
    padding: 50px 50px;
  }
}

@media (max-width: 1199px) {
  .price-item--agency {
    padding: 50px 15px;
  }
}

.price-item--agency::before, .price-item--agency::after {
  display: none;
}

.price-item--agency:hover {
  border: 3px solid #0554f2;
  border-radius: 10px;
}

.price-item--agency:hover .price-item__content_item i {
  color: #ffffff;
}

.price-item--agency .price-item__content_item span {
  color: #14133b;
}

.price-item--agency .price-item__content_item i {
  color: #14133b;
}

.counter__item_count {
  font-size: 60px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 13px;
  display: inline-block;
}

.counter__item_title {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 400;
}

@media (max-width: 1199px) {
  .counter__item_title {
    font-size: 26px;
  }
}

.counter__software_wrapper {
  border-radius: 10px;
  padding: 61px 10px 31px;
  margin-top: -104px;
  position: relative;
  z-index: 1;
}

.counter__software .counter-number {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .counter__software .counter-number {
    font-size: 2.5rem;
  }
}

.counter__software .heading {
  font-size: 26px;
}

.counter__modern {
  display: flex;
}

.counter__modern .counter-number {
  margin-bottom: 0;
  color: #0554f2;
  font-size: calc(1.5rem + 3vw);
  font-weight: 300;
}

@media (min-width: 1200px) {
  .counter__modern .counter-number {
    font-size: 3.75rem;
  }
}

.counter__modern .heading {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  margin-left: 23px;
}

.counter__startup {
  padding: 64px 25px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 95px 0px rgba(0, 0, 0, 0.05);
  margin: 1px;
}

.counter__startup .counter-number {
  color: #0554f2;
  margin-bottom: 5px;
}

.counter__startup .heading {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .counter__startup .heading {
    font-size: 1.5rem;
  }
}

.skills-startup {
  padding-right: 85px;
  padding-left: 21px;
  padding-bottom: 40px;
  position: relative;
}

@media (max-width: 767px) {
  .skills-startup {
    padding-right: 0;
    padding-left: 0;
  }
}

.skills-startup__shape {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -25px;
}

.blog-item {
  padding: 28px;
  border-top: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .blog-item {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .blog-item {
    border: 2px solid #ededed !important;
    border-radius: 10px !important;
  }
}

.blog-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 80.64px 3.36px rgba(0, 0, 0, 0.04);
  transform: scaleX(1.012) scaleY(1.01);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: 1;
}

.blog-item__column:first-child .blog-item {
  border-left: 2px solid #ededed;
  border-radius: 20px 0px 0px 20px;
}

@media (max-width: 991px) {
  .blog-item__column:first-child .blog-item {
    border-radius: 0;
  }
}

.blog-item__column:last-child .blog-item {
  border-right: 2px solid #ededed;
  border-radius: 0px 20px 20px 0px;
}

@media (max-width: 991px) {
  .blog-item__column:last-child .blog-item {
    border-radius: 0;
  }
}

.blog-item:hover .blog-item__img_link::after {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.blog-item:hover::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item__article {
  position: relative;
  z-index: 2;
}

.blog-item__header {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 2;
}

.blog-item__img_link {
  display: block;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item__img_link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.blog-item__img_link .row,
.blog-item__img_link .inner {
  position: relative;
  z-index: 2;
}

.blog-item__img_link::after {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  top: 50%;
  height: 0%;
  width: 0%;
  border-radius: 100%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
}

.blog-item__tags {
  position: absolute;
  font-size: 16px;
  background: #0554f2;
  color: #ffffff;
  padding: 6px 15px;
  display: inline-block;
  text-transform: capitalize;
  top: 20px;
  right: 20px;
  border-radius: 5px;
  border: 2px solid transparent;
}

.blog-item__tags:hover, .blog-item__tags:focus {
  border: 2px solid #ffffff;
  color: #ffffff;
  background: transparent;
}

.blog-item__date {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #0554f2;
  display: block;
  margin-bottom: 3px;
}

.blog-item__title {
  margin-bottom: 16px;
}

.blog-item__list {
  display: inline-block;
  margin-right: 33px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .blog-item__list {
    margin-right: 1px;
  }
}

.blog-item__list:last-child {
  margin-right: 0;
}

.blog-item__list i {
  color: #0554f2;
  font-size: 15px;
  margin-right: 9px;
}

.blog-item--software {
  padding: 0;
  border: 0 !important;
  border-radius: 5px !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  overflow: inherit;
}

.blog-item--software::before {
  display: none;
}

.blog-item--software:hover {
  transform: translateY(-10px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item--software:hover .header::before {
  background: rgba(5, 84, 242, 0.6);
  filter: blur(20px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item--software .header {
  border-radius: 10px 10px 0 0;
}

.blog-item--software .header::before {
  position: absolute;
  content: "";
  height: 160px;
  width: 580px;
  bottom: -84px;
  z-index: 1;
  border-radius: 10px 10px 0 0;
  text-align: center;
  filter: blur(20px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: rgba(0, 0, 0, 0.95);
  left: 50%;
  transform: translateX(-50%);
}

.blog-item--software .header .link--image::after {
  display: none;
}

.blog-item--software .meta {
  position: absolute;
  bottom: 20px;
  left: 25px;
  z-index: 3;
}

.blog-item--software .meta .list {
  font-size: 14px;
  color: #ffffff;
}

.blog-item--software .meta .list .icon {
  color: #ffffff;
}

.blog-item--software .meta .list .name {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.blog-item--software .content {
  padding: 32px 25px;
  background-color: #ffffff;
  box-shadow: 0px 7px 70px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 10px 10px;
}

@media (max-width: 575px) {
  .blog-item--software .content {
    padding: 30px 20px;
  }
}

.blog-item--software .content .tags a {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #0554f2;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item--software .content .tags a:hover {
  color: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-item--big .header::before {
  width: 1023px;
  height: 298px;
  bottom: -202px;
  opacity: 0.95;
  filter: blur(30px) !important;
}

.blog-item--big .content {
  padding: 44px 49px 50px 50px;
}

.blog-item--big .title--big {
  margin-bottom: 15px;
}

/*=============================
//  rt-card
=============================*/
.rt-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 7px 70px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 50px 37px 58px;
}

.rt-card.search-box {
  padding: 41px 25px;
}

.rt-card.search-box .search-form-box .form-group {
  position: relative;
}

.rt-card.search-box .search-form-box .form-group button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  font-size: 14px;
  color: #0554f2;
  background: transparent;
  border: 0;
}

.rt-card.search-box .search-form-box .form-group .rt-search-control {
  color: #939396;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 6px 0;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.rt-card.search-box .search-form-box .form-group .rt-search-control::placeholder {
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: #939396;
}

.rt-card.search-box .search-form-box .form-group .rt-search-control:focus {
  outline: 0;
  box-shadow: none;
}

.rt-card .card-heading {
  font-size: 22px;
  color: #14133b;
  font-weight: 600;
}

.rt-card .download-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rt-card .download-area .info-part {
  display: flex;
}

.rt-card .download-area .info-part .icon {
  margin-right: 16px;
}

.rt-card .download-area .heading .title {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1;
}

.rt-card .download-area .heading .title a {
  color: #000000;
}

.rt-card .download-area .heading p {
  margin-bottom: 0;
}

.rt-card .download-area .heading .link {
  font-size: 16px;
  color: #646464;
  font-weight: 600;
}

.rt-card .download-area .icon-part a {
  background-color: #0554f2;
  width: 39px;
  height: 39px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
}

.rt-card .download-area .icon-part a:hover {
  color: #ffffff;
  background-color: #03359b;
}

.rt-card .blog-list li a {
  display: block;
  font-size: 16px;
  color: #14133b;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 26px;
}

.rt-card .blog-list li a span {
  padding-left: 10px;
}

.rt-card .blog-list li a:hover {
  color: #0554f2;
}

.rt-card .blog-list li:last-child a {
  margin-bottom: 0;
}

.rt-card .blog-list li:hover a {
  letter-spacing: 0.15em;
}

.rt-card .blog-post--small {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rt-card .blog-post--small .post-thumb {
  min-width: 100px;
  margin-right: 15px;
}

.rt-card .blog-post--small .post-thumb img {
  border-radius: 5px;
}

.rt-card .blog-post--small .post-title {
  font-size: 18px;
  margin-bottom: 0;
}

.rt-card .blog-post--small .post-meta {
  color: #5b6674;
}

.rt-card .blog-post--small:last-child {
  margin-bottom: 0;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px;
}

.tag-list .tag-link {
  display: inline-flex;
  height: 45px;
  align-items: center;
  font-size: 16px;
  background: #f2f2f2;
  padding: 10px 16px;
  border-radius: 5px;
  color: #14133b;
  margin: 5px 5px;
}

.tag-list .tag-link:hover {
  background: #0554f2;
  color: #ffffff;
}

.instagram ul {
  margin: -5px;
}

.instagram ul li {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.instagram ul li a img {
  border-radius: 5px;
}

.blog-details .meta li {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 30px;
  color: #0554f2;
}

.blog-details .meta li i {
  font-size: 16px;
}

.blog-details .paragraph {
  padding-right: 15px;
}

.blog-details .quotes {
  font-size: 18px;
  font-weight: 600;
  padding: 33px 90px 33px 150px;
  font-style: italic;
  position: relative;
  border: 1px solid #e1e1e2;
  border-radius: 5px;
}

.blog-details .quotes i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-flex;
  font-size: 49px;
  color: #999999;
  left: 70px;
}

.blog-details .blog-item__img {
  border-radius: 10px 10px 0 0;
}

.blog-details .share {
  justify-content: flex-end;
  align-items: center;
}

.blog-details .share > ul {
  text-align: right;
}

.blog-details .share > ul > li {
  position: relative;
}

.blog-details .share > ul > li > ul {
  opacity: 0;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.blog-details .share > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  right: 60px;
}

.blog-details .share .tag-link:hover i {
  color: #ffffff;
}

.blog-details .share .tag-link:hover .header-top__social {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-details .blog-author {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 40px 58px;
  border: 1px solid rgba(20, 19, 59, 0.15);
}

.blog-details .blog-author__image {
  min-width: 130px;
  margin-right: 23px;
}

.blog-details .blog-author__image img {
  border-radius: 50%;
}

.blog-details .blog-author__content .title--big {
  font-size: 26px;
  font-weight: 500;
}

.blog-details .blog-author__content .title--small {
  font-size: 18px;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}

.blog-details .blog-author__content .paragraph {
  margin-bottom: 0;
  padding-right: 0;
}

.blog-details .blog-arrow {
  padding-top: 25px;
}

.blog-details .blog-arrow .link {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #14133b;
}

.blog-details .blog-arrow .link:hover {
  color: #0554f2;
}

.blog-details .blog-arrow .link i {
  display: inline-flex;
  margin-right: 13px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.blog-details .blog-arrow .link--next i {
  margin-right: 0;
  margin-left: 13px;
}

.blog-details .blog-comment {
  padding-top: 30px;
}

.blog-details .blog-comment .comment {
  padding: 0;
  border: 1px solid transparent;
  padding-right: 70px;
}

.blog-details .blog-comment .comment .blog-author__image {
  min-width: 100px;
  margin-right: 20px;
}

.blog-details .blog-comment .comment .blog-author__content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
}

.blog-details .blog-comment .comment .blog-author__content .title button {
  font-size: 14px;
  background: transparent;
  color: #5b6674;
  border: 1px solid transparent;
}

.blog-details .blog-comment .comment .blog-author__content .title button:hover {
  color: #0554f2;
}

.blog-details .blog-comment .comment .blog-author__content .paragraph {
  padding-right: 28px;
}

.blog-details .blog-comment .comment.reply {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 125px;
}

.blog-details .blog-comment .comment.reply:last-child {
  padding-bottom: 0;
}

.contact-address--bg {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  padding: 74px 40px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

.contact-address--bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 0;
}

.contact-address--bg .row,
.contact-address--bg .inner {
  position: relative;
  z-index: 2;
}

@media (max-width: 575px) {
  .contact-address--bg {
    padding: 50px 15px;
  }
}

.contact-address__item {
  display: flex;
  position: relative;
  z-index: 2;
}

.contact-address__item_icon {
  min-width: 45px;
  padding-top: 8px;
}

.contact-address__item_icon i {
  font-size: 40px;
  color: #ffffff;
}

.contact-address__item_text {
  padding-left: 16px;
}

.contact-address__item_text h3, .contact-address__item_text .h3 {
  font-weight: 400;
}

.contact-address__item_text p {
  margin-bottom: 0;
}

.contact-address__item_text p i {
  font-weight: 500;
  font-style: italic;
  font-size: 24px;
}

.contact-address__form {
  width: 100%;
  position: relative;
}

.contact-address__form_input {
  width: 100%;
  border: 1px solid #ffffff;
  background: transparent;
  font-size: 18px;
  color: #ffffff;
  border-radius: 5px;
  padding: 15px 30px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-address__form_input::placeholder {
  color: #ffffff;
}

.contact-address__form_button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
  color: #ffffff;
  background: transparent;
  border: 1px solid transparent;
  right: 30px;
}

.contact-address__form:hover .contact-address__form_input, .contact-address__form:focus .contact-address__form_input {
  background: #ffffff;
  color: #14133b;
  border: 1px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-address__form:hover .contact-address__form_input::placeholder, .contact-address__form:focus .contact-address__form_input::placeholder {
  color: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-address__form:hover .contact-address__form_button, .contact-address__form:focus .contact-address__form_button {
  color: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-form {
  padding-left: 15px;
}

@media (max-width: 575px) {
  .contact-form {
    padding-left: 0;
  }
}

.contact-form__input {
  width: 100%;
  border: 1px solid #ededed;
  font-size: 15px;
  height: 57px;
  border-radius: 3px;
  padding: 12px 24px;
  margin-bottom: 16px;
  color: #14133b;
  font-family: "Roboto", sans-serif;
  outline: none;
  background: transparent;
}

.contact-form__input::placeholder {
  color: #14133b;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.contact-form__input_textarea {
  height: 150px;
}

.contact-form__button {
  font-size: 18px;
  font-weight: 500;
  display: block;
  width: 100%;
  padding: 13px 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  background: #0554f2;
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-form__button:hover {
  background: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-form .nice-select {
  border: 1px solid #ededed;
  line-height: 30px;
  outline: 0;
}

.contact-form .nice-select::after {
  border-bottom: 2px solid #14133b;
  border-right: 2px solid #14133b;
  height: 8px;
  right: 26px;
  width: 8px;
}

.contact-form .nice-select .list {
  width: 100%;
}

.business-item:nth-child(odd) .business-item__content {
  background: #f8faff;
}

.business-item:nth-child(odd) .business-item__content::before {
  background: #e0e8f9;
}

.business-item:nth-child(odd) .business-item__content:hover::after {
  background: #0554f2;
}

.business-item:nth-child(even) .business-item__content {
  background: #f1f3f9;
}

.business-item:nth-child(even) .business-item__content::before {
  background: #e6e6e6;
}

.business-item:first-child .business-item__content {
  border-radius: 10px 0 0 10px;
}

.business-item:last-child .business-item__content {
  border-radius: 0px 10px 10px 0px;
}

.business-item__content {
  padding: 58px 32px 50px;
  background: #ffffff;
  position: relative;
}

@media (max-width: 1199px) {
  .business-item__content {
    padding: 58px 15px 50px;
  }
}

.business-item__content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 77px;
  left: 0;
  right: 0;
}

.business-item__content::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  top: 77px;
  left: 0;
  right: 0;
  z-index: 2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
}

.business-item__content .number {
  display: inline-block;
  line-height: 40px;
  height: 40px;
  width: 40px;
  font-size: 15px;
  color: #0554f2;
  background-color: #ebf1fb;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: 3;
}

.business-item__content .title {
  font-size: 24px;
  margin-bottom: 11px;
}

.business-item__content .link {
  color: #0554f2;
  font-size: 18px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.business-item__content .link:hover {
  padding-left: 5px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  color: #14133b;
}

.business-item__content:hover .number {
  background: #0554f2;
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.business-item__content:hover::after {
  width: 100%;
  opacity: 1;
  visibility: visible;
  background: #0554f2;
}

.brand-active .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.brand-active .brand-item {
  text-align: center;
  display: flex;
  justify-content: center;
}

.brand-active .brand-item__col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-active .brand-item__logo {
  filter: grayscale(100%);
  transition: all 1s ease;
}

.brand-active .brand-item :hover .brand-item__logo {
  -webkit-filter: invert(100%) sepia(92%) saturate(4039%) hue-rotate(222deg) brightness(97%) contrast(97%);
  filter: invert(100%) sepia(92%) saturate(4039%) hue-rotate(222deg) brightness(97%) contrast(97%);
  transition: all 1s ease;
}

.featured-agency {
  display: flex;
  padding: 47px 27px;
  background: #212046;
  border-radius: 10px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 991px) {
  .featured-agency {
    padding: 47px 20px;
  }
}

.featured-agency:hover {
  background: #33325d;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.featured-agency:hover .header .icon {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.featured-agency .header {
  min-width: 70px;
  padding-top: 8px;
}

@media (max-width: 1199px) {
  .featured-agency .header {
    min-width: 50px;
  }
}

.featured-agency .header .icon {
  font-size: calc(1.5rem + 3vw);
  color: #0554f2;
  display: inline-flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .featured-agency .header .icon {
    font-size: 3.75rem;
  }
}

@media (max-width: 1199px) {
  .featured-agency .header .icon {
    font-size: calc(1.375rem + 1.5vw);
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .featured-agency .header .icon {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .featured-agency .header .icon {
    font-size: calc(1.5rem + 3vw);
  }
}

@media (max-width: 767px) and (min-width: 1200px) {
  .featured-agency .header .icon {
    font-size: 3.75rem;
  }
}

.featured-agency .article {
  padding-left: 8px;
  text-align: left;
}

.featured-agency .article .heading {
  font-size: 26px;
  color: #ffffff;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .featured-agency .article .heading {
    font-size: 24px;
  }
}

.featured-agency .article .heading a {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.featured-agency .article .paragraph {
  color: #c7c9cc;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.featured-agency .article .link {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: transparent;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

.featured-agency .article .link:hover {
  color: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.feature-block {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.feature-block .image {
  width: 100%;
}

@media (max-width: 575px) {
  .feature-block .image {
    min-height: 249px;
    object-fit: cover;
  }
}

.feature-block__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 40px 20px;
  transform: translateY(60px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 991px) {
  .feature-block__content {
    transform: translateY(50px);
  }
}

@media (max-width: 575px) {
  .feature-block__content {
    padding: 35px 20px 10px;
    transform: translateY(50px);
  }
}

.feature-block__content::before {
  position: absolute;
  content: "";
  width: 673px;
  height: 169px;
  left: 50%;
  transform: translateX(-47%);
  bottom: -30px;
  background: #000000;
  filter: blur(60.7px);
  opacity: 0.75;
  transition: height 0.4s;
}

.feature-block .heading {
  font-size: calc(1.3rem + 0.6vw);
  color: #ffffff;
  font-weight: 500;
  transform: translateY(22px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .feature-block .heading {
    font-size: 1.75rem;
  }
}

.feature-block .paragraph {
  color: #ffffff;
  transform: translateY(15px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.feature-block:hover .feature-block__content {
  transform: translateY(0px);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.feature-block:hover .feature-block__content::before {
  width: 835px;
  height: 355px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -160px;
  background: #0554f2;
  filter: blur(60.7px);
  opacity: 1;
  transition: height 0.4s;
}

.feature-block:hover .heading {
  transform: translateY(0px);
  transition-delay: 0.2s;
}

.feature-block:hover .paragraph {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
  transition-delay: 0.4s;
}

.feature-list > ul .item {
  position: relative;
  padding-left: 35px;
  margin-bottom: 16px;
}

.feature-list > ul .item > i {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #0acc4f;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.feature-list > ul .item > span {
  font-family: "Roboto", sans-serif;
}

.feature-list > ul .item:last-child {
  margin-bottom: 0;
}

.project-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.project-item:hover .image__link {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: translateY(-50%) scaleY(1);
}

.project-item:hover .image .heading,
.project-item:hover .image .tags {
  opacity: 1;
  visibility: visible;
}

.project-item:hover .image .heading {
  transform: translateY(-47px);
  transition: 1s;
}

.project-item:hover .image .tags {
  transform: translateY(-30px);
  transition: 1.2s;
  color: rgba(255, 255, 255, 0.8);
}

.project-item:hover .image__big {
  transform: scale(1.4);
  transition: 1.5s;
}

.project-item .image {
  display: block !important;
}

.project-item .image__big {
  width: 100%;
  transition: 1s;
}

.project-item .image__link {
  position: absolute;
  right: 0;
  left: 0;
  width: 70px;
  height: 70px;
  display: inline-block;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-30%) scaleY(0);
  background: #f5f5f5;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.project-item .image__link:hover {
  background: #0554f2;
  color: #ffffff;
}

.project-item .item-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(20, 19, 59, 0.6);
  top: 0;
  left: 100%;
}

.project-item .heading,
.project-item .tags {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

.project-item .heading {
  transform: translateY(0px);
  font-size: 24px;
  font-weight: 500;
}

.project-item .tags {
  transform: translateY(0px);
  color: #ffffff;
  font-size: 16px;
}

.case-item {
  padding: 0 20px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  position: relative;
}

@media (max-width: 1666px) {
  .case-item {
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  .case-item {
    padding: 0 15px;
  }
}

@media (max-width: 1199px) {
  .case-item {
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .case-item {
    padding: 0 15px;
  }
}

.case-item__content {
  padding: 15px 15px;
  border-radius: 5px;
  margin: 0 30px;
  margin-top: -40px;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .case-item__content {
    margin: -40px 30px 0 30px;
  }
}

@media (max-width: 1666px) {
  .case-item__content {
    margin: -40px 15px 0 15px;
  }
}

@media (max-width: 575px) {
  .case-item__content {
    margin: -40px 0px 0 0px;
  }
}

.case-item__content h5, .case-item__content .h5 {
  font-size: calc(1.275rem + 0.3vw);
  margin-bottom: 2px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (min-width: 1200px) {
  .case-item__content h5, .case-item__content .h5 {
    font-size: 1.5rem;
  }
}

@media (max-width: 1366px) {
  .case-item__content h5, .case-item__content .h5 {
    font-size: 1.25rem;
  }
}

.case-item__content h5:hover a, .case-item__content .h5:hover a {
  color: #ffffff;
}

.case-item__content span {
  color: #d7d7ef;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-item__image {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}

.case-item__image a {
  display: block;
  overflow: hidden;
}

.case-item__image a img {
  transition: 1.5s;
}

.case-item:hover .case-item__image a img {
  transform: scale(1.1);
  transition: 2s;
}

.case-item:hover .case-item__content {
  background: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-item:hover .case-item__content h5, .case-item:hover .case-item__content .h5 {
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-item:hover .case-item__content span {
  color: #d8d8ef;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-software {
  position: relative;
  padding: 0 0;
}

.case-software__image {
  box-shadow: none;
}

.case-software__content {
  position: absolute;
  margin: 0 0 0 30px;
  bottom: 17px;
  padding: 19px 193px 19px 30px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  text-align: left !important;
  transform: scale(0.9);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .case-software__content {
    margin: 0 0 0 17px;
    padding: 19px 95px 19px 30px;
  }
}

@media (max-width: 1199px) {
  .case-software__content {
    padding: 19px 50px 19px 25px;
  }
}

.case-software__content .tags {
  display: block;
}

.case-software__content .link {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  background: #fff;
  text-align: center;
  border-radius: 5px;
  right: 0px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-software__content .link:hover {
  background: #14133b;
  color: #ffffff;
}

.case-software__content .link:hover i {
  transform: rotate(360deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-software:hover .case-software__content {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.case-software:hover .case-software__content .link {
  right: -33px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-software--small .case-software__content {
  margin: 0 0 0 20px;
  bottom: 17px;
  padding: 10px 49px 10px 20px;
}

.case-software--small .case-software__content .link {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.case-choose .image {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-choose .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(5, 84, 242, 0.7);
  z-index: 0;
}

.case-choose .image .row,
.case-choose .image .inner {
  position: relative;
  z-index: 2;
}

.case-choose .image::after {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-choose .image a {
  display: inline-block;
  width: 100%;
}

.case-choose .image a img {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-choose__content {
  padding-top: 23px;
}

.case-choose__content .paragraph {
  margin-bottom: 25px;
  padding-right: 50px;
}

@media (max-width: 1199px) {
  .case-choose__content .paragraph {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .case-choose__content .paragraph {
    padding-right: 0;
  }
}

.case-choose__content .link {
  font-size: 16px;
  font-weight: 500;
  color: #0554f2;
}

.case-choose__content .link:hover {
  color: #14133b;
  padding-left: 15px;
}

.case-choose:hover .image a img {
  transform: scale(1.3);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-choose:hover .image::after {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-choose:hover .case-choose__content .heading a {
  color: #0554f2;
}

.case-details__header {
  padding: 0 70px;
}

@media (max-width: 767px) {
  .case-details__header {
    padding: 0 0px;
  }
}

.case-details__header ul {
  margin-bottom: 38px;
}

.case-details__header ul li {
  display: inline-flex;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  color: #5b6674;
}

.case-details__header ul li span {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-details__header ul li span a {
  color: #5b6674;
}

.case-details__header ul li span a:hover {
  color: #14133b;
}

.case-details__header ul li i {
  font-size: 13px;
  margin-right: 10px;
}

.case-details__header ul li:hover span {
  color: #14133b;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-details__row {
  margin-right: -10px;
  margin-left: -10px;
}

.case-details__row .item {
  padding-right: 10px;
  padding-left: 10px;
}

.case-details__gallery {
  border-radius: 5px;
  overflow: hidden;
}

.case-details__article .heading {
  max-width: 636px;
  font-size: 35px;
  text-align: center;
  padding: 0 15px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.case-details__article .paragraph {
  padding: 0 86px;
  text-align: center;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .case-details__article .paragraph {
    padding: 0 0px;
  }
}

.case-details__video {
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.case-details__video .video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.case-details__video .video .video-link {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 24px;
}

@media (max-width: 575px) {
  .case-details__video .video .video-link {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 16px;
  }
}

.case-details__social .header-top__social {
  text-align: left;
  padding: 30px 0;
}

@media (max-width: 767px) {
  .case-details__social .header-top__social {
    text-align: center;
  }
}

.case-details__social .header-top__social ul li {
  margin-left: 0px;
  margin-right: 7px;
}

.case-details__social .case-instagram-hit {
  text-align: right;
}

@media (max-width: 767px) {
  .case-details__social .case-instagram-hit {
    text-align: center;
  }
}

.case-details__social .case-instagram-hit p {
  font-size: 20px;
  text-align: right;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.case-details__social .case-instagram-hit p i {
  margin-right: 13px;
}

.case-details__mover {
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.case-details__mover .row {
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .case-details__mover .mover--left {
    text-align: center;
  }
}

.case-details__mover .mover .link {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #14133b;
}

.case-details__mover .mover .link i {
  display: inline-flex;
  margin-right: 13px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-details__mover .mover .link h3, .case-details__mover .mover .link .h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  padding-right: 23px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-details__mover .mover .link img {
  border-radius: 5px;
}

.case-details__mover .mover .link:hover {
  color: #0554f2;
}

.case-details__mover .mover .link:hover i,
.case-details__mover .mover .link:hover h3,
.case-details__mover .mover .link:hover .h3 {
  color: #0554f2;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.case-details__mover .mover--middle {
  text-align: center;
}

.case-details__mover .mover--middle .link i {
  margin-right: 0;
  font-size: 20px;
  color: #5b6674;
}

.case-details__mover .mover--last {
  text-align: right;
}

@media (max-width: 767px) {
  .case-details__mover .mover--last {
    text-align: center;
  }
}

.case-details__related {
  padding-top: 95px;
}

.case-modern {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.case-modern::before, .case-modern::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.case-modern::before {
  background-color: rgba(20, 19, 59, 0.75);
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.case-modern::after {
  border: 2px solid rgba(255, 255, 255, 0.16);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale3d(1, 1, 1);
  border-radius: 5px;
  z-index: 2;
  transition: all 1.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  opacity: 0;
  visibility: hidden;
}

.case-modern__image .link {
  position: absolute;
  bottom: 0px;
  left: 50%;
  display: inline-block;
  justify-content: center;
  background: #ffffff;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: 500;
  color: #0554f2;
  padding: 13px 32px;
  border-radius: 5px 5px 0 0;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-modern__image .link:hover {
  background-color: #0554f2;
  color: #ffffff;
}

.case-modern__content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
  z-index: 3;
  overflow: hidden;
}

.case-modern__content .tags {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #fbfcff;
  display: block;
  margin-bottom: 8px;
}

.case-modern__content .tags > span {
  transform: translateY(-30px);
  display: inline-block;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-modern__content .heading {
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

.case-modern__content .heading > a {
  transform: translateY(35px);
  display: inline-block;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  color: #ffffff;
}

.case-modern__content .heading > a:hover {
  color: #ffffff;
}

.case-modern:hover {
  transition: all 0.3s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-modern:hover::before {
  transform: scale3d(1.5, 1.5, 1.5);
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  opacity: 1;
  visibility: visible;
}

.case-modern:hover::after {
  transform: translateY(-50%) translateX(-50%) scale3d(0.85, 0.79, 0.85);
  transition: all 1.5s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  opacity: 1;
  visibility: visible;
}

.case-modern:hover .case-modern__image .link {
  opacity: 1;
  visibility: visible;
  bottom: 45px;
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-modern:hover .case-modern__content .tags > span {
  transform: translateY(0px);
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-modern:hover .case-modern__content .heading > a {
  transform: translateY(0px);
  transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85);
}

.case-menu {
  margin-bottom: 40px;
  text-align: center;
}

.case-menu button {
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  color: #5b6674;
  background: transparent;
  border: 0;
  padding: 0;
  margin-right: 25px;
  margin-bottom: 10px;
}

.case-menu button:last-child {
  margin-right: 0;
}

.case-menu button.active {
  color: #0554f2;
}

.case-block--startup .item-overlay {
  background: rgba(5, 84, 242, 0.8);
}

.fullpage-section__bg {
  position: absolute;
  width: 50%;
  height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.fullpage-section .bg-color {
  background: #09091d;
}

.fullpage-section .bg-left {
  left: 0;
}

.fullpage-section .bg-left .fullpage-service--right {
  right: 0;
  left: auto;
}

.fullpage-section .bg-left .fullpage-service--right .image,
.fullpage-section .bg-left .fullpage-service--right .content {
  border-radius: 15px 0px 0px 15px;
}

.fullpage-section .bg-right {
  right: 0;
}

.fullpage-section .bg-right .fullpage-service--left {
  right: auto;
}

@media (max-width: 1199px) {
  .fullpage-section .bg-right .fullpage-service--left {
    width: 180px;
    display: none;
  }
}

.fullpage-section .bg-right .fullpage-service--left .image,
.fullpage-section .bg-right .fullpage-service--left .content {
  border-radius: 0 15px 15px 0;
}

.fullpage-section__content {
  padding-left: 120px;
  padding-right: 15%;
}

@media (max-width: 1366px) {
  .fullpage-section__content {
    padding-left: 40px;
    padding-right: 10%;
  }
}

@media (max-width: 1666px) {
  .fullpage-section__content {
    padding-left: 30px;
    padding-right: 5%;
  }
}

@media (max-width: 767px) {
  .fullpage-section__content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
}

.fullpage-section__content .title--small {
  font-size: 24px;
  color: #bfbebe;
  font-weight: 400;
  letter-spacing: 0.2em;
}

@media (max-width: 1199px) {
  .fullpage-section__content .title--small {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .fullpage-section__content .title--small {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .fullpage-section__content .title--small {
    font-size: 16px;
  }
}

.fullpage-section__content .title--big {
  font-size: calc(1.5rem + 3vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .fullpage-section__content .title--big {
    font-size: 3.75rem;
  }
}

@media (max-width: 1199px) {
  .fullpage-section__content .title--big {
    font-size: calc(1.4375rem + 2.25vw);
  }
}

@media (max-width: 1199px) and (min-width: 1200px) {
  .fullpage-section__content .title--big {
    font-size: 3.125rem;
  }
}

@media (max-width: 991px) {
  .fullpage-section__content .title--big {
    font-size: calc(1.375rem + 1.5vw);
  }
}

@media (max-width: 991px) and (min-width: 1200px) {
  .fullpage-section__content .title--big {
    font-size: 2.5rem;
  }
}

.fullpage-section__content .paragraph {
  color: #bfbebe;
  font-size: 1.25rem;
  margin-bottom: 37px;
  line-height: 1.4;
}

@media (max-width: 1199px) {
  .fullpage-section__content .paragraph {
    font-size: 0.9375rem;
  }
}

@media (max-width: 991px) {
  .fullpage-section__content .paragraph {
    font-size: 0.9375rem;
  }
}

@media (max-width: 767px) {
  .fullpage-section__content .paragraph {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .fullpage-section .ms-right.ms-easing {
    display: none;
  }
}

@media (max-width: 767px) {
  .fullpage-section .ms-left.ms-easing {
    width: 100% !important;
  }
}

.fullpage-service {
  position: absolute;
}

@media (max-width: 767px) {
  .fullpage-service {
    position: static;
    padding: 30px 15px;
  }
  .fullpage-service .image,
  .fullpage-service .content {
    border-radius: 0 !important;
    width: 100%;
  }
}

.fullpage-service--left {
  right: 0;
  bottom: 56px;
}

@media (max-width: 1366px) {
  .fullpage-service--left {
    bottom: 0px;
  }
}

.fullpage-service .image {
  border-radius: 15px 0 0 15px;
}

.fullpage-service--right {
  left: 0;
  bottom: 267px;
}

@media (max-width: 1366px) {
  .fullpage-service--right {
    bottom: 211px;
  }
}

.fullpage-service .content {
  border-radius: 0px 15px 15px 0px;
  text-align: center;
  background: #ffffff;
  min-width: 240px;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fullpage-service .content .icon {
  font-size: calc(1.39375rem + 1.725vw);
  color: #000000;
}

@media (min-width: 1200px) {
  .fullpage-service .content .icon {
    font-size: 2.6875rem;
  }
}

.fullpage-service .content .title--small {
  font-size: 22px;
  color: #0554f2;
  font-weight: 400;
}

.fullpage-service .content .title--big {
  font-size: 30px;
  color: #14133b;
  font-weight: 600;
}

@media (max-width: 767px) {
  #multiscroll-nav {
    display: none;
  }
}

#multiscroll-nav li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 25px;
  position: relative;
}

#multiscroll-nav li a {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

#multiscroll-nav li a span {
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  background: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

#multiscroll-nav li .active {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

#multiscroll-nav li .active span {
  background: #ffffff;
  width: 20px;
  height: 20px;
  top: -3px;
  left: -3px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 767px) {
  .fullpage-section .ms-left {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .fullpage-section .ms-right {
    display: none;
  }
}

.newsletter__wrapper {
  background: #f0f9fe;
  padding: 0px 60px;
  border-radius: 15px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .newsletter__wrapper {
    padding: 40px 60px;
  }
}

@media (max-width: 767px) {
  .newsletter__wrapper {
    padding: 35px 30px;
  }
}

.newsletter__form_input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.newsletter__form_input input {
  min-width: 330px;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 0 19px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .newsletter__form_input input {
    min-width: 100%;
  }
}

.newsletter__form_input button {
  height: 50px;
  background: #0554f2;
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 30px;
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

@media (max-width: 575px) {
  .newsletter__form_input button {
    margin-left: 0;
  }
}

.newsletter__form_input button:hover {
  background: #14133b;
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.newsletter__form_agree span {
  color: #7b7b7c;
  margin-left: 5px;
}

.newsletter__thumb {
  padding-top: 40px;
  text-align: right;
}

@media (max-width: 991px) {
  .newsletter__thumb {
    text-align: center;
  }
}

.newsletter--minus {
  margin-bottom: -85px;
  position: relative;
  z-index: 1;
}

.team-block {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block .image {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 34px;
  position: relative;
}

.team-block .image::before {
  position: absolute;
  content: "";
  width: 261px;
  height: 634px;
  bottom: -75%;
  filter: blur(0px);
  background: rgba(0, 0, 0, 0.6);
  right: -44%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: 99999;
}

.team-block .content .designation {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #868686;
}

.team-block .content .name {
  font-size: 22px;
}

.team-block:hover .social ul li {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block:hover .social ul li:nth-child(1n) {
  transition-delay: 0.1s;
}

.team-block:hover .social ul li:nth-child(2n) {
  transition-delay: 0.3s;
}

.team-block:hover .social ul li:nth-child(3n) {
  transition-delay: 0.5s;
}

.team-block:hover .social ul li:nth-child(4n) {
  transition-delay: 0.7s;
}

.team-block:hover .social ul li:nth-child(5n) {
  transition-delay: 0.9s;
}

.team-block:hover .image::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  filter: blur(100px);
  background: rgba(0, 0, 0, 0.56);
}

.team-block--two {
  text-align: center;
}

.team-block--two .image {
  margin-bottom: 24px;
}

.team-block--two .image__member {
  transform: translateX(50px) scale(1.3) translateY(50px);
  filter: grayscale(100%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--two:hover .image::before {
  display: none;
}

.team-block--two:hover .image__member {
  transform: translateX(0px) scale(1) translateY(0px);
  filter: grayscale(0%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--two:hover .content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--two .social {
  right: 0;
  left: 0;
}

.team-block--two .social ul li {
  display: inline-block;
}

.team-block--two .social ul li a {
  margin: 0 6.5px;
}

.team-block--two .content {
  transform: translateY(-50px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--two .content .name {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
}

.team-block--two .content .name .designation {
  padding-left: 12px;
  font-family: "Roboto", sans-serif;
  color: #868686;
  text-transform: capitalize;
  letter-spacing: 0;
  padding-top: 6px;
}

.team-block--active .image::before {
  display: none;
}

.team-block--active .image__member {
  transform: translateX(0px) scale(1) translateY(0px);
  filter: grayscale(0%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--active .content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--active .social ul li {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.team-block--active .social ul li:nth-child(1n) {
  transition-delay: 0.1s;
}

.team-block--active .social ul li:nth-child(2n) {
  transition-delay: 0.3s;
}

.team-block--active .social ul li:nth-child(3n) {
  transition-delay: 0.5s;
}

.team-block--active .social ul li:nth-child(4n) {
  transition-delay: 0.7s;
}

.team-block--active .social ul li:nth-child(5n) {
  transition-delay: 0.9s;
}

.social {
  position: absolute;
  bottom: 31.5px;
  right: 26px;
  z-index: 99999;
}

.social ul li {
  transform: translateX(15px);
  opacity: 0;
  visibility: visible;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition-delay: 0s;
}

.social ul li:nth-child(1n) {
  transition-delay: 0.1s;
}

.social ul li:nth-child(2n) {
  transition-delay: 0.3s;
}

.social ul li:nth-child(3n) {
  transition-delay: 0.5s;
}

.social ul li:nth-child(4n) {
  transition-delay: 0.7s;
}

.social ul li:nth-child(5n) {
  transition-delay: 0.9s;
}

.social ul li a {
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  background: #ffffff;
  margin: 6.5px 0;
  border-radius: 50%;
  font-size: 1.125rem;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.social ul li a:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  background: #0554f2;
  color: #ffffff;
}

.contact__agency {
  text-align: center;
  padding: 104px 0;
}

@media (max-width: 575px) {
  .contact__agency {
    padding: 50px 0;
  }
}

.contact__agency .title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.08em;
  display: block;
  margin-bottom: 7px;
}

@media (max-width: 1199px) {
  .contact__agency .title {
    font-size: 18px;
  }
}

.contact__agency .heading {
  font-size: 40px;
  margin-bottom: 40px;
  letter-spacing: 0.06em;
}

@media (max-width: 1199px) {
  .contact__agency .heading {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .contact__agency .heading {
    font-size: 25px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .contact__agency .heading {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.contact--agency:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.contact--agency .row,
.contact--agency .inner {
  position: relative;
  z-index: 2;
}

.contact-form--main {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.07);
  padding: 53px 45px;
}

.contact-item {
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.07);
  padding: 57px 0;
}

.contact-item .icon {
  margin-bottom: 17px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.contact-item:hover .icon {
  animation: jump 0.4s linear;
}

.contact-item .title--heading {
  font-size: 26px;
  font-weight: 600;
}

.contact-item .address,
.contact-item .link {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.6;
  color: #5b6674;
}

.map-box.style-1 {
  height: 480px;
  border-radius: 15px;
}

.contact-thumb--startup {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -11;
}

.contact-padding--startup {
  padding-top: 200px;
  padding-bottom: 200px;
}

@media (max-width: 1199px) {
  .contact-padding--startup {
    padding-top: 130px;
    padding-bottom: 100px;
  }
}

.contact-startup--bg {
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.contact-startup--bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.5);
  z-index: 0;
}

.contact-startup--bg .row,
.contact-startup--bg .inner {
  position: relative;
  z-index: 2;
}

.contact__startup {
  padding: 92px 0;
}

.contact__startup .title {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  .contact__startup .title {
    font-size: 1.875rem;
  }
}

.contact__startup .heading {
  font-size: calc(1.4375rem + 2.25vw);
}

@media (min-width: 1200px) {
  .contact__startup .heading {
    font-size: 3.125rem;
  }
}

.hero-inner__bg {
  min-height: 510px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 991px) {
  .hero-inner__bg {
    min-height: 330px;
  }
}

@media (max-width: 575px) {
  .hero-inner__bg {
    background-position: -130px;
  }
}

.hero-inner__bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.35);
  z-index: 0;
}

.hero-inner__bg .row,
.hero-inner__bg .inner {
  position: relative;
  z-index: 2;
}

.hero-inner__bg_two:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.75);
  z-index: 0;
}

.hero-inner__bg_two .row,
.hero-inner__bg_two .inner {
  position: relative;
  z-index: 2;
}

.hero-inner__bg_three:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(20, 19, 59, 0.85);
  z-index: 0;
}

.hero-inner__bg_three .row,
.hero-inner__bg_three .inner {
  position: relative;
  z-index: 2;
}

.page-breadcrumb {
  text-align: center;
  padding-top: 105px;
}

@media (max-width: 991px) {
  .page-breadcrumb {
    padding-top: 80px;
  }
}

.page-breadcrumb .heading {
  font-size: calc(1.4375rem + 2.25vw);
  font-weight: 500;
  color: #ffffff;
}

@media (min-width: 1200px) {
  .page-breadcrumb .heading {
    font-size: 3.125rem;
  }
}

.page-breadcrumb .breadcrumb {
  justify-content: center;
}

.page-breadcrumb .breadcrumb .breadcrumb-item {
  font-size: 1.125rem;
  color: #ffffff;
  font-weight: 300;
}

.page-breadcrumb .breadcrumb .breadcrumb-item::before {
  color: #ffffff;
  font-weight: 500;
}

.page-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #ffffff;
  font-weight: 400;
}

.page-breadcrumb .breadcrumb .breadcrumb-item a:hover {
  color: #0554f2;
}

.about-skill--feature {
  padding-right: 20px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .about-skill--feature {
    padding-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .about-skill--feature {
    padding-right: 0;
    margin-left: 0;
  }
}

.about-skill--feature .item:last-child .progress {
  margin-bottom: 10px;
}

.about-skill .progress {
  background-color: #dedbdb;
  border-radius: 20px;
  box-shadow: none;
  height: 3px;
  margin-bottom: 53px;
  overflow: visible;
  position: relative;
  margin-top: 44px;
  border: 1px solid transparent;
  padding: 0;
}

.about-skill .progress .lead {
  font-size: 22px;
  bottom: 14px;
  position: absolute;
  color: #14133b;
  font-weight: 400;
}

.about-skill .progress-bar {
  background: #0554f2;
  overflow: visible;
  height: 3px;
}

.about-skill .progress-bar::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #0554f2;
}

.about-skill .progress-bar > span {
  color: #14133b;
  float: right;
  font-size: 18px;
  margin-right: 10px;
  margin-top: -25px;
  position: absolute;
  padding: 5px;
  top: -20px;
  right: -28px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .progress-wrap.active-progress {
    opacity: 1;
  }
}

.progress-wrap {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 200ms linear, margin-right 0ms;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap {
    transition: none;
  }
}

.progress-wrap.active-progress {
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap:after {
  position: absolute;
  content: "\f062";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-align: center;
  line-height: 2.3rem;
  font-size: 14px;
  color: #0554f2;
  left: 0;
  top: 0;
  height: 2.3rem;
  width: 2.3rem;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap:after {
    transition: none;
  }
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #0554f2;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

@media (prefers-reduced-motion: reduce) {
  .progress-wrap svg.progress-circle path {
    transition: none;
  }
}

.faq-accordion .accordion-item {
  margin-bottom: 29px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.faq-accordion .accordion-item:last-of-type {
  margin-bottom: 0;
}

.faq-accordion .accordion-item .accordion-header .accordion-button {
  font-size: 20px;
  color: #ffffff;
  background: transparent;
  background: #0554f2;
  border-radius: 5px;
  padding: 17px 23px 17px 30px;
  border: 1px solid transparent;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .faq-accordion .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .faq-accordion .accordion-item .accordion-header .accordion-button {
    font-size: 16px;
    padding: 15px 20px 15px 20px;
  }
}

.faq-accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
  outline: inherit;
}

.faq-accordion .accordion-item .accordion-header .accordion-button::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  background-image: none;
  font-size: 16px;
  text-align: right;
}

.faq-accordion .accordion-item .accordion-header .collapsed {
  background: transparent;
  color: #14133b;
  border: 1px solid #dedbdb;
}

.faq-accordion .accordion-body {
  padding: 25px 30px;
  background-color: white;
  box-shadow: 0px 7px 65px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .faq-accordion .accordion-body {
    padding: 15px 20px;
  }
}

.faq-accordion .accordion-body p {
  color: #5b6674;
  margin-bottom: 0;
}

.faq-accordion--startup .accordion-item {
  margin-bottom: 9px;
}

.faq-accordion--startup .accordion-item .accordion-header .accordion-button {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
}

.faq-accordion--startup .accordion-item .accordion-header .accordion-button::after {
  font-size: 13px;
}

.timeline-item {
  position: relative;
  z-index: 9;
}

.timeline-item .timeline-header .heading {
  font-size: calc(1.35rem + 1.2vw);
  font-weight: 500;
  margin-bottom: 27px;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .timeline-item .timeline-header .heading {
    font-size: 2.25rem;
  }
}

.timeline-item .timeline-image {
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
}

.timeline-item .timeline-content {
  padding: 30px 39px;
  background-color: #ffffff;
  box-shadow: 0px 0px 54px 0px rgba(2, 2, 2, 0.05);
  position: relative;
  max-width: 447px;
}

.timeline-item .timeline-content .heading {
  font-size: 22px;
}

.timeline-item .timeline-content::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  filter: drop-shadow(0px 0px 54px rgba(2, 2, 2, 0.4)) drop-shadow(0px 0px 54px rgba(2, 2, 2, 0.4));
  top: 40px;
}

.timeline-item--left {
  padding-right: 45px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

@media (max-width: 991px) {
  .timeline-item--left {
    padding-right: 0;
    padding-left: 45px;
    align-items: flex-start;
  }
}

.timeline-item--left .timeline-content::after {
  border-width: 15px 0 15px 16px;
  right: -16px;
  border-color: transparent transparent transparent #ffffff;
}

@media (max-width: 991px) {
  .timeline-item--left .timeline-content::after {
    border-width: 15px 16px 15px 0;
    left: -16px;
    border-color: transparent #ffffff transparent transparent;
  }
}

.timeline-item--left .timeline-circle {
  right: -24.5px;
}

@media (max-width: 991px) {
  .timeline-item--left .timeline-circle {
    right: auto;
    left: -13.5px;
  }
}

.timeline-item--right {
  padding-left: 45px;
}

.timeline-item--right .timeline-content::after {
  border-width: 15px 16px 15px 0;
  left: -16px;
  border-color: transparent #ffffff transparent transparent;
}

.timeline-item--right .timeline-circle {
  left: -24.5px;
}

@media (max-width: 991px) {
  .timeline-item--right .timeline-circle {
    left: -13.5px;
  }
}

.timeline-item .timeline-circle {
  width: 25px;
  height: 25px;
  background: #eaf1ff;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  z-index: 1;
}

.timeline-item .timeline-circle::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #0554f2;
  z-index: 2;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  left: 0;
  border-radius: 50%;
}

.timeline-wrapper--line::before, .timeline-wrapper--line::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  background-color: #f4f4f4;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

@media (max-width: 991px) {
  .timeline-wrapper--line::before, .timeline-wrapper--line::after {
    left: 0;
  }
}

@media (max-width: 767px) {
  .timeline-wrapper__row:last-child .timeline-item .timeline-image {
    margin-bottom: 80px;
  }
}

.careers-block {
  padding: 38px 55px 18px 55px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 100px;
}

@media (max-width: 991px) {
  .careers-block {
    padding: 38px 45px 18px 45px;
    margin: 0 0px;
  }
}

@media (max-width: 767px) {
  .careers-block {
    margin: 0 0;
  }
}

@media (max-width: 575px) {
  .careers-block {
    padding: 15px 20px 5px 20px;
  }
}

.careers-block .job-button .btn {
  background: #eff4ff;
  color: #0554f2;
  font-weight: 500;
}

.careers-block .job-button .btn:hover {
  background: #0554f2;
  color: #ffffff;
}

.careers-block .job-meta .item {
  margin-right: 80px;
  font-weight: 500;
}

.careers-block .job-meta .item:last-child {
  margin-right: 0;
}

.careers-block .job-button,
.careers-block .job-meta {
  margin-bottom: 20px;
}

.feature-career {
  border-radius: 10px;
  background-color: white;
  padding: 35px 0;
  margin-bottom: 20px;
}

.feature-career h5, .feature-career .h5 {
  font-weight: 500;
  margin-bottom: 0;
}

.feature-career img {
  margin-bottom: 14px;
}

.feature-career:hover img {
  animation: jump 0.4s linear;
}

.rt-pagination .pagination {
  justify-content: center;
}

.rt-pagination .pagination .page-item .page-link {
  height: 45px;
  width: 50px;
  text-align: center;
  line-height: 45px;
  padding: 0;
  margin: 0px 6px !important;
  font-size: 18px;
  font-weight: 600;
  background: #e6eff4;
  border-radius: 5px;
  border: 1px solid transparent;
  color: #14133b;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.rt-pagination .pagination .page-item .page-link:hover, .rt-pagination .pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
  background: #0554f2;
  color: #ffffff;
}

.rt-pagination .pagination .page-item .page-link span {
  display: inline-flex;
}

.rt-pagination .pagination .page-item .page-link span i {
  display: inline-flex;
}

.error-inner {
  text-align: center;
}

.error-inner .error-content .heading {
  font-size: calc(1.4375rem + 2.25vw);
  font-weight: 600;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .error-inner .error-content .heading {
    font-size: 3.125rem;
  }
}

.error-inner .error-content .paragraph {
  padding: 0 41px;
  margin-bottom: 31px;
}

.error-bg {
  background-repeat: no-repeat;
  background-position: center;
}

.error__thumb {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.error-thumb--two {
  position: absolute;
  left: -60px;
  bottom: 0;
}

/* ===================================
    Page Loader
====================================== */
.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 9999999;
  background: #ffffff;
}

.loader .cssload-loader {
  width: 65px;
  height: 65px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  top: 50%;
}

.loader .cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.loader .cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  -o-animation: cssload-rotate-one 1.15s linear infinite;
  -ms-animation: cssload-rotate-one 1.15s linear infinite;
  -webkit-animation: cssload-rotate-one 1.15s linear infinite;
  -moz-animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 5px solid #0554f2;
}

.loader .cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  -o-animation: cssload-rotate-two 1.15s linear infinite;
  -ms-animation: cssload-rotate-two 1.15s linear infinite;
  -webkit-animation: cssload-rotate-two 1.15s linear infinite;
  -moz-animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 5px solid #0554f2;
}

.loader .cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  -o-animation: cssload-rotate-three 1.15s linear infinite;
  -ms-animation: cssload-rotate-three 1.15s linear infinite;
  -webkit-animation: cssload-rotate-three 1.15s linear infinite;
  -moz-animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 5px solid #0554f2;
}

@keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.template-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.5s ease-in-out;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transform-origin: 0 0 0;
}

.template-search .animation-shape-lines {
  color: #0554f2;
}

.template-search .animation-shape-lines::before, .template-search .animation-shape-lines::after {
  background-color: #0554f2;
}

.template-search .search-form {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 60%;
  margin: 0 auto;
}

.template-search .search-form input[type="search"] {
  color: rgba(20, 19, 59, 0.7);
  font-size: 24px;
  text-align: left;
  border: 1px solid #c3c3c3;
  margin: 0 auto;
  padding: 25px 10px 25px 30px;
  outline: none;
  background-color: transparent;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .template-search .search-form input[type="search"] {
    width: 75%;
  }
}

.template-search .search-form .search-btn {
  padding: 24px 10px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 4px;
  height: 80px;
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  background: #0554f2;
  color: #ffffff;
  border-radius: 0;
}

.template-search .search-form .search-btn i:before {
  font-size: 24px;
  font-weight: 700;
  margin-left: 0;
}

.template-search .search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.template-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #0554f2;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  opacity: 1;
  visibility: visible;
  padding: 3px 15px 5px;
  font-size: 36px;
  font-weight: 300;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in-out;
}

.template-search .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.template-search .close:hover {
  background-color: white;
}

.template-search.open {
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
